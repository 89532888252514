import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  TablePagination,
  Alert,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fetchCustomerLoyaltyTrans } from '../services/api';

const statusDescriptions = {
  1: 'Pending',
  2: 'Active',
  3: 'Declined',
  4: 'Closed',
  5: 'Returned',
  6: 'Joined',
  7: 'Void',
  8: 'Draft',
};

const RewardEarningsTable = ({ customerMobileNumber }) => {
  const [transactions, setTransactions] = useState([]);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const loadTransactions = async (mobileNumber, page, rowsPerPage) => {
    setIsLoading(true);
    try {
      const data = await fetchCustomerLoyaltyTrans(mobileNumber, page + 1, rowsPerPage);
      setTransactions(data.rewardEarnings);
      setTotalRecords(data.totalRecords);
    } catch (err) {
      console.error('Error fetching reward earnings:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customerMobileNumber) {
      loadTransactions(customerMobileNumber, page, rowsPerPage);
    }
  }, [customerMobileNumber, page, rowsPerPage]);

  const handleViewClick = (transaction) => {
    setCurrentTransaction(transaction);
    setIsViewDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentTransaction(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper sx={{ mt: 4, p: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error.message}
        </Alert>
      )}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Earned Points</TableCell>
                  <TableCell>Order Status</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell>{transaction.order_id}</TableCell>
                    <TableCell>
                      {transaction.order_status === 5
                        ? `-${transaction.earned_points}`
                        : transaction.earned_points}
                    </TableCell>
                    <TableCell>
                      {statusDescriptions[transaction.order_status] || transaction.order_status}
                    </TableCell>
                    <TableCell>{new Date(transaction.createdAt).toLocaleString()}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewClick(transaction)} sx={{ color: '#1976D2' }}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </>
      )}
    </Paper>
  );
};

export default RewardEarningsTable;
