import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fetchAllRewards, fetchGroupLoyaltyRewards, deleteReward, fetchFoodicsMenu, toggleRewardActive } from '../services/api';
import EditLoyaltyReward from './EditLoyaltyReward';
import ViewRewardDetails from './ViewRewardDetails';
import AuthenticatedImage from './AuthenticatedImage';

const ViewLoyaltyRewards = ({ groupId }) => {
  const [rewards, setRewards] = useState([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [currentReward, setCurrentReward] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isConfirmActivateDialogOpen, setIsConfirmActivateDialogOpen] = useState(false);
  const [isConfirmDeactivateDialogOpen, setIsConfirmDeactivateDialogOpen] = useState(false);
  const [rewardsToDelete, setRewardsToDelete] = useState([]);
  const [foodicsProducts, setFoodicsProducts] = useState([]);
  const [selectedRewards, setSelectedRewards] = useState([]);
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const sortRewardsByID = (rewards) => {
    return rewards.sort((a, b) => b.id - a.id);
  };

  const loadRewards = async () => {
    const data = groupId === 'all' ? await fetchAllRewards() : await fetchGroupLoyaltyRewards(groupId);
    const sortedData = sortRewardsByID(data);
    setRewards(sortedData);
  };

  const loadFoodicsProducts = async () => {
    try {
      const data = await fetchFoodicsMenu();
      setFoodicsProducts(data);
    } catch (err) {
      console.error('Failed to load Foodics products:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([loadRewards(), loadFoodicsProducts()]);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setIsLoading(false);
      }
    };
   
    loadData();
  }, [groupId]);

  const getProductNames = (allowedProductsString) => {
  
    let allowedProducts;
    try {
      allowedProducts = JSON.parse(allowedProductsString);
    } catch (error) {
      console.error('Error parsing allowed products:', error);
      return 'Error: Invalid product data';
    }
  
    if (!allowedProducts || !allowedProducts.products_list || allowedProducts.products_list.length === 0) {
      return 'None';
    }
  
    if (!foodicsProducts || foodicsProducts.length === 0) {
      return 'Loading product names...';
    }
  
    const names = allowedProducts.products_list.map((product_id) => {
      const product = foodicsProducts.find((p) => p.product_id === product_id);
      return product ? product.name : product_id;
    }).filter(Boolean); // This will remove any undefined or null values
  

    
    const productsList = names.length > 0 ? names.join(', ') : 'No matching products found';
    return `Quantity: ${allowedProducts.quantity}, Products: ${productsList}`;
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case 1:
        return 'Order Discount';
      case 2:
        return 'Product/Combo Discount';
      default:
        return type;
    }
  };

  const handleEditClick = (reward) => {
    setCurrentReward(reward);
    setIsEditDialogOpen(true);
    setIsViewDialogOpen(false);
  };

  const handleViewClick = (reward) => {
    setCurrentReward(reward);
    setIsViewDialogOpen(true);
  };

  const handleEditDialogClose = (updated) => {
    if (updated) {
      loadRewards();
    }
    setIsEditDialogOpen(false);
    setCurrentReward(null);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentReward(null);
  };

  const handleCheckboxChange = (event, rewardId) => {
    if (event.target.checked) {
      setSelectedRewards([...selectedRewards, rewardId]);
    } else {
      setSelectedRewards(selectedRewards.filter(id => id !== rewardId));
    }
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedRewards(rewards.map(reward => reward.id));
    } else {
      setSelectedRewards([]);
    }
  };

  const handleActionChange = (event) => {
    const selectedAction = event.target.value;
    setAction(selectedAction);

    switch (selectedAction) {
      case 'delete':
        setRewardsToDelete(selectedRewards);
        setIsConfirmDialogOpen(true);
        break;
      case 'activate':
        setIsConfirmActivateDialogOpen(true);
        break;
      case 'deactivate':
        setIsConfirmDeactivateDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const handleToggleActive = async (setActive) => {
    try {
      await Promise.all(selectedRewards.map(id => toggleRewardActive(id, setActive)));
      loadRewards();
      setSelectedRewards([]);
      setAction('');
    } catch (error) {
      console.error(`Error ${setActive ? 'activating' : 'deactivating'} rewards:`, error);
    } finally {
      setAction('');
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await Promise.all(rewardsToDelete.map(id => deleteReward(id)));
      loadRewards();
    } catch (error) {
      console.error('Error deleting rewards:', error);
    } finally {
      setIsConfirmDialogOpen(false);
      setRewardsToDelete([]);
      setSelectedRewards([]);
      setAction('');
    }
  };

  const handleConfirmActivate = async () => {
    await handleToggleActive(true);
    setIsConfirmActivateDialogOpen(false);
  };

  const handleConfirmDeactivate = async () => {
    await handleToggleActive(false);
    setIsConfirmDeactivateDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setIsConfirmDialogOpen(false);
    setRewardsToDelete([]);
    setSelectedRewards([]);
    setAction('');
  };

  const handleCancelActivate = () => {
    setIsConfirmActivateDialogOpen(false);
    setSelectedRewards([]);
    setAction('');
  };

  const handleCancelDeactivate = () => {
    setIsConfirmDeactivateDialogOpen(false);
    setSelectedRewards([]);
    setAction('');
  };

  return (
    <>
      {selectedRewards.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <FormControl>
            <Select
              value={action}
              onChange={handleActionChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Actions for selected items
              </MenuItem>
              <MenuItem value="delete">Delete</MenuItem>
              <MenuItem value="activate">Activate</MenuItem>
              <MenuItem value="deactivate">Deactivate</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={handleSelectAllChange}
                  checked={selectedRewards.length === rewards.length && rewards.length > 0}
                  indeterminate={selectedRewards.length > 0 && selectedRewards.length < rewards.length}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Reward Title</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Reward Points</TableCell>
              <TableCell>Allowed Products</TableCell>
              <TableCell>Picture</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewards.map((reward) => (
              <TableRow key={reward.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRewards.includes(reward.id)}
                    onChange={(event) => handleCheckboxChange(event, reward.id)}
                  />
                </TableCell>
                <TableCell>{reward.id}</TableCell>
                <TableCell>{reward.reward_title}</TableCell>
                <TableCell>{getTypeLabel(reward.type)}</TableCell>
                <TableCell>{reward.reward_points}</TableCell>
                <TableCell>
                  {isLoading ? (
                    'Loading...'
                  ) : (
                    getProductNames(reward.allowed_products)
                  )}
                </TableCell>
                <TableCell>
                  {reward.picture && (
                    <AuthenticatedImage 
                      src={reward.picture}
                      alt={reward.reward_title}
                      style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleViewClick(reward)} sx={{ color: '#1976D2' }}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Typography color={reward.set_active ? "primary" : "error"}>
                    {reward.set_active ? "Active" : "Deactivated"}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {currentReward && (
        <ViewRewardDetails
          open={isViewDialogOpen}
          onClose={handleViewDialogClose}
          reward={currentReward}
          foodicsProducts={foodicsProducts}
          onEditClick={() => handleEditClick(currentReward)}
        />
      )}

      {currentReward && (
        <EditLoyaltyReward
          open={isEditDialogOpen}
          onClose={handleEditDialogClose}
          reward={currentReward}
        />
      )}

      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected reward(s)? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmActivateDialogOpen}
        onClose={handleCancelActivate}
      >
        <DialogTitle>Confirm Activate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate the selected reward(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelActivate} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmActivate} color="primary">
            Activate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmDeactivateDialogOpen}
        onClose={handleCancelDeactivate}
      >
        <DialogTitle>Confirm Deactivate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate the selected reward(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeactivate} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeactivate} color="secondary">
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewLoyaltyRewards;