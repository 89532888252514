import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Alert,
  DialogContentText
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { fetchFoodicsMenu, syncFoodicsMenu, toggleFoodicsMenuActive } from '../services/api';
import FoodicsMenuItemDetails from './FoodicsMenuItemDetails';
import EditFoodicsMenuItem from './EditFoodicsMenuItem';
import AuthenticatedImage from './AuthenticatedImage';

const FoodicsMenuItems = () => {
  const [items, setItems] = useState([]);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmActivateDialogOpen, setIsConfirmActivateDialogOpen] = useState(false);
  const [isConfirmDeactivateDialogOpen, setIsConfirmDeactivateDialogOpen] = useState(false);

  const loadItems = async () => {
    setIsLoading(true);
    try {
      const data = await fetchFoodicsMenu();
      setItems(data);
    } catch (err) {
      console.error('Error fetching Foodics menu:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  const handleViewClick = (item) => {
    setCurrentItem(item);
    setIsViewDialogOpen(true);
  };

  const handleEditClick = (item) => {
    setCurrentItem(item);
    setIsEditDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentItem(null);
  };

  const handleEditDialogClose = (updated) => {
    setIsEditDialogOpen(false);
    if (updated) {
      loadItems();
    }
    setCurrentItem(null);
  };

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemId]);
    } else {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    }
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedItems(items.map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleActionChange = (event) => {
    const selectedAction = event.target.value;
    setAction(selectedAction);

    switch (selectedAction) {
      case 'activate':
        setIsConfirmActivateDialogOpen(true);
        break;
      case 'deactivate':
        setIsConfirmDeactivateDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const handleToggleActive = async (setActive) => {
    try {
      await Promise.all(selectedItems.map(id => toggleFoodicsMenuActive(id, setActive)));
      loadItems();
      setSelectedItems([]);
      setAction('');
    } catch (error) {
      console.error(`Error ${setActive ? 'activating' : 'deactivating'} menu items:`, error);
      setError(error);
    } finally {
      setAction('');
    }
  };

  const handleConfirmActivate = async () => {
    await handleToggleActive(true);
    setIsConfirmActivateDialogOpen(false);
  };

  const handleConfirmDeactivate = async () => {
    await handleToggleActive(false);
    setIsConfirmDeactivateDialogOpen(false);
  };

  const handleCancelActivate = () => {
    setIsConfirmActivateDialogOpen(false);
    setAction('');
    setSelectedItems([]);
  };

  const handleCancelDeactivate = () => {
    setIsConfirmDeactivateDialogOpen(false);
    setAction('');
    setSelectedItems([]);
  };

  const handleSyncFoodicsMenu = async () => {
    try {
      await syncFoodicsMenu();
      loadItems();
    } catch (error) {
      console.error('Error syncing Foodics menu:', error);
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error.message}
        </Alert>
      )}
      <Box display="flex" justifyContent="flex-end" mb={3}>
        <Button variant="contained" color="primary" onClick={handleSyncFoodicsMenu}>
          Update from Foodics
        </Button>
      </Box>
      <Box sx={{ mt: 2, gap: 2, mb: 3 }}>
        {selectedItems.length > 0 && (
          <FormControl>
            <Select
              value={action}
              onChange={handleActionChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Actions for selected items
              </MenuItem>
              <MenuItem value="activate">Activate</MenuItem>
              <MenuItem value="deactivate">Deactivate</MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={handleSelectAllChange}
                    checked={selectedItems.length === items.length && items.length > 0}
                    indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
                  />
                </TableCell>
                <TableCell>Product ID</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Product Description</TableCell>
                <TableCell>Product Image</TableCell>
                <TableCell>Product Price</TableCell>
                <TableCell>Is Active</TableCell>
                <TableCell>View</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedItems.includes(item.id)}
                      onChange={(event) => handleCheckboxChange(event, item.id)}
                    />
                  </TableCell>
                  <TableCell>{item.product_id}</TableCell>
                  <TableCell>{item.product_name}</TableCell>
                  <TableCell>{item.product_description}</TableCell>
                  <TableCell>
                    {item.product_image && (
                      <AuthenticatedImage 
                        src={item.product_image}
                        alt={item.product_name}
                        style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{item.product_price}</TableCell>
                  <TableCell>
                    <Typography color={item.set_active ? "primary" : "error"}>
                      {item.set_active ? "Active" : "Deactivated"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleViewClick(item)} sx={{ color: '#1976D2' }}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditClick(item)} sx={{ color: '#1976D2' }}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {currentItem && (
        <FoodicsMenuItemDetails
          open={isViewDialogOpen}
          onClose={handleViewDialogClose}
          item={currentItem}
        />
      )}

      <EditFoodicsMenuItem
        open={isEditDialogOpen}
        onClose={handleEditDialogClose}
        item={currentItem}
      />

      <Dialog
        open={isConfirmActivateDialogOpen}
        onClose={handleCancelActivate}
      >
        <DialogTitle>Confirm Activate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate the selected item(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelActivate} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmActivate} color="primary">
            Activate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmDeactivateDialogOpen}
        onClose={handleCancelDeactivate}
      >
        <DialogTitle>Confirm Deactivate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate the selected item(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeactivate} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeactivate} color="secondary">
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FoodicsMenuItems;