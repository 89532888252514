import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { updateFoodicsMenuItem, fetchMenuCategories } from '../services/api';
import ImageUpload from './ImageUpload';

const EditFoodicsMenuItem = ({ open, onClose, item }) => {
  const [formData, setFormData] = useState({
    product_name: '',
    product_description: '',
    product_image: '',
    product_price: '',
    category_id: ''
  });
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchMenuCategories();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    loadCategories();
  }, []);

  useEffect(() => {
    if (item) {
      setFormData({
        product_name: item.product_name || '',
        product_description: item.product_description || '',
        product_image: item.product_image || '',
        product_price: item.product_price ? item.product_price.toString() : '',
        category_id: item.category_id || ''
      });
    }
  }, [item]);

  const validateFields = () => {
    const newErrors = {};
    if (!formData.product_name.trim()) {
      newErrors.product_name = 'Product name is required.';
    }
    if (!formData.product_price.trim()) {
      newErrors.product_price = 'Product price is required.';
    } else if (isNaN(Number(formData.product_price))) {
      newErrors.product_price = 'Product price must be a number.';
    }
    if (!formData.category_id) {
      newErrors.category_id = 'Category is required.';
    }
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      if (errors.product_image) {
        setErrors((prev) => ({ ...prev, product_image: '' }));
      }
    }
  };

  const resetForm = () => {
    setFormData({
      product_name: item?.product_name || '',
      product_description: item?.product_description || '',
      product_image: item?.product_image || '',
      product_price: item?.product_price ? item.product_price.toString() : '',
      category_id: item?.category_id || ''
    });
    setImage(null);
    setErrors({});
    setMessage('');
  };

  const handleClose = (saved = false) => {
    resetForm();
    onClose(saved);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    if (image) {
      formDataToSend.append('product_image', image);
    }

    try {
      const response = await updateFoodicsMenuItem(item.id, formDataToSend);
      setMessage(response.message || 'Successfully updated menu item');
      handleClose(true);
    } catch (error) {
      console.error('Error updating menu item:', error);
      setMessage('Failed to update menu item');
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Menu Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill out the form below to edit the menu item.
        </DialogContentText>
        {Object.entries(formData).map(([key, value]) => (
          key !== 'product_image' && key !== 'category_id' && (
            <FormControl key={key} fullWidth margin="normal">
              <TextField
                label={key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                name={key}
                value={value}
                onChange={handleInputChange}
                placeholder={`Enter ${key.replace('_', ' ')}`}
                multiline={key === 'product_description'}
                rows={key === 'product_description' ? 3 : 1}
                error={!!errors[key]}
                helperText={errors[key]}
                type={key === 'product_price' ? 'number' : 'text'}
              />
            </FormControl>
          )
        ))}
        <FormControl fullWidth margin="normal" error={!!errors.category_id}>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            name="category_id"
            value={formData.category_id}
            onChange={handleInputChange}
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          {errors.category_id && <DialogContentText color="error">{errors.category_id}</DialogContentText>}
        </FormControl>
        <ImageUpload
          onChange={handleImageChange}
          error={errors.product_image}
          value={image}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save Menu Item
        </Button>
      </DialogActions>
      {message && <DialogContentText style={{ padding: '0 24px 20px' }}>{message}</DialogContentText>}
    </Dialog>
  );
};

export default EditFoodicsMenuItem;
