import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { fetchEveryCustomerGroups, addCustomerToGroup, fetchCustomerGroups, removeCustomerFromGroup } from '../services/api';

const UpdateCustomerGroup = ({ open, onClose, customer }) => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const allGroups = await fetchEveryCustomerGroups();
        setGroups(allGroups);
      } catch (err) {
        console.error('Failed to load groups:', err);
        setError(err);
      }
    };

    loadGroups();
  }, []);

  useEffect(() => {
    if (customer) {
      const loadCustomerGroups = async () => {
        try {
          const customerGroups = await fetchCustomerGroups(customer.customer_mobile_number, customer.mobile_country_code);
          setSelectedGroups(customerGroups);
        } catch (err) {
          console.error('Failed to load customer groups:', err);
          setError(err);
        }
      };

      loadCustomerGroups();
    }
  }, [customer]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const currentGroupIds = groups
        .filter(group => selectedGroups.includes(group.group_name))
        .map(group => group.id);
      const removedGroupIds = groups
        .filter(group => !selectedGroups.includes(group.group_name))
        .map(group => group.id);

      for (let group_id of currentGroupIds) {
        const data = {
          customer_mobile_number: customer.customer_mobile_number,
          mobile_country_code: customer.mobile_country_code,
          group_id,
        };
        await addCustomerToGroup(data);
      }

      for (let group_id of removedGroupIds) {
        const data = {
          customer_mobile_number: customer.customer_mobile_number,
          mobile_country_code: customer.mobile_country_code,
          group_id,
        };
        await removeCustomerFromGroup(data);
      }

      setMessage('Customer groups updated successfully');
      onClose(true);
    } catch (error) {
      console.error('Error updating customer group:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGroupChange = (event) => {
    setSelectedGroups(event.target.value);
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Update Customer Group</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select new groups for the customer.
        </DialogContentText>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <DialogContentText color="error">{error.message}</DialogContentText>
        ) : (
          <FormControl fullWidth margin="normal">
            <InputLabel>Groups</InputLabel>
            <Select
              multiple
              value={selectedGroups}
              onChange={handleGroupChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.group_name}>
                  <Checkbox checked={selectedGroups.includes(group.group_name)} />
                  <ListItemText primary={group.group_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={loading}>
          Save
        </Button>
      </DialogActions>
      {message && <DialogContentText>{message}</DialogContentText>}
    </Dialog>
  );
};

export default UpdateCustomerGroup;
