import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  IconButton,
  TablePagination,
  TextField,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { fetchAllCustomers } from '../services/api';
import UpdateCustomerGroup from './UpdateCustomerGroup';

const ViewCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const loadCustomers = useCallback(
    debounce(async (page, rowsPerPage, search) => {
      setIsLoading(true);
      try {
        const { customers, totalRecords } = await fetchAllCustomers(page + 1, rowsPerPage, search);
        setCustomers(customers);
        setTotalRecords(totalRecords);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }, 1000), // Delay of 300ms
    []
  );

  useEffect(() => {
    loadCustomers(page, rowsPerPage, search);
  }, [page, rowsPerPage, search]);

  const handleEditClick = (customer) => {
    setCurrentCustomer(customer);
    setIsDialogOpen(true);
  };

  const handleViewClick = (customer) => {
    navigate(`/customer-loyalty-trans/${customer.customer_mobile_number}`);
  };

  const handleDialogClose = (updated) => {
    if (updated) {
      loadCustomers(page, rowsPerPage, search);
    }
    setIsDialogOpen(false);
    setCurrentCustomer(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Paper sx={{ mt: 4, p: 2 }}>
      <Typography variant="h4" gutterBottom>
        All Customers
      </Typography>
      <Box mb={2}>
        <TextField
          label="Search by Mobile Number"
          variant="outlined"
          fullWidth
          value={search}
          onChange={handleSearchChange}
        />
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">Error fetching customers: {error.message}</Typography>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Country Code</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Groups</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => (
                  <TableRow key={customer.id}>
                    <TableCell>{customer.id}</TableCell>
                    <TableCell>{customer.customer_name}</TableCell>
                    <TableCell>{customer.customer_mobile_number}</TableCell>
                    <TableCell>{customer.mobile_country_code}</TableCell>
                    <TableCell>{customer.customer_email || 'Not Provided'}</TableCell>
                    <TableCell>
                      {customer.groups && customer.groups.length > 0
                        ? customer.groups.join(', ')
                        : 'No groups assigned'}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditClick(customer)} sx={{ color: '#1976D2' }}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewClick(customer)} sx={{ color: '#1976D2' }}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </>
      )}
      {currentCustomer && (
        <UpdateCustomerGroup
          open={isDialogOpen}
          onClose={handleDialogClose}
          customer={currentCustomer}
        />
      )}
    </Paper>
  );
};

export default ViewCustomers;
