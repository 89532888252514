import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  FormHelperText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateReward, fetchEveryCustomerGroups, fetchGroupIdsForReward, fetchFoodicsMenu, fetchAuthenticatedImage } from '../services/api';
import ImageUpload from './ImageUpload';
import AuthenticatedImage from './AuthenticatedImage';

const EditLoyaltyReward = ({ open, onClose, reward }) => {
  const [type, setType] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [isPercent, setIsPercent] = useState(false);
  const [isDiscountTaxable, setIsDiscountTaxable] = useState(false);
  const [maxDiscountAmount, setMaxDiscountAmount] = useState('');
  const [discountIncludesModifiers, setDiscountIncludesModifiers] = useState(false);
  const [allowedProducts, setAllowedProducts] = useState([]);
  const [allowedProductsQuantity, setAllowedProductsQuantity] = useState('');
  const [groupIds, setGroupIds] = useState([]);
  const [groups, setGroups] = useState([]);
  const [rewardPoints, setRewardPoints] = useState('');
  const [picture, setPicture] = useState(null);
  const [picturePreview, setPicturePreview] = useState('');
  const [rewardTitle, setRewardTitle] = useState('');
  const [rewardDescription, setRewardDescription] = useState('');
  const [message, setMessage] = useState('');
  const [foodicsProducts, setFoodicsProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const data = await fetchEveryCustomerGroups();
        setGroups(data);
      } catch (err) {
        console.error('Failed to load groups:', err);
      }
    };

    const loadFoodicsProducts = async () => {
      try {
        const data = await fetchFoodicsMenu();
        setFoodicsProducts(data);
      } catch (err) {
        console.error('Failed to load Foodics products:', err);
      }
    };

    loadGroups();
    loadFoodicsProducts();
  }, []);

  useEffect(() => {
    if (reward) {
      setType(reward.type.toString());
      setDiscountAmount(reward.discount_amount.toString());
      setIsPercent(reward.is_percent);
      setIsDiscountTaxable(reward.is_discount_taxable);
      setMaxDiscountAmount(reward.max_discount_amount.toString());
      setDiscountIncludesModifiers(reward.discount_includes_modifiers);
      
      if (reward.allowed_products) {
        try {
          const parsedAllowedProducts = JSON.parse(reward.allowed_products);
          setAllowedProducts(parsedAllowedProducts.products_list || []);
          setAllowedProductsQuantity(parsedAllowedProducts.quantity ? parsedAllowedProducts.quantity.toString() : '');
        } catch (error) {
          console.error('Error parsing allowed_products:', error);
          setAllowedProducts([]);
          setAllowedProductsQuantity('');
        }
      } else {
        setAllowedProducts([]);
        setAllowedProductsQuantity('');
      }
      
      setRewardPoints(reward.reward_points.toString());
      setPicturePreview(reward.picture || '');
      setRewardTitle(reward.reward_title);
      setRewardDescription(reward.reward_description);
  
      const fetchGroupIds = async () => {
        try {
          const groupIds = await fetchGroupIdsForReward(reward.id);
          setGroupIds(groupIds.map(group => group.group_id));
        } catch (err) {
          console.error('Failed to load group IDs for reward:', err);
        }
      };
  
      fetchGroupIds();
    }
  }, [reward]);

  const resetForm = () => {
    setErrors({});
    setMessage('');
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.type = type ? "" : "Type is required";
    tempErrors.discountAmount = discountAmount ? "" : "Discount amount is required";
    tempErrors.maxDiscountAmount = maxDiscountAmount ? "" : "Max discount amount is required";
    tempErrors.rewardPoints = rewardPoints ? "" : "Reward points are required";
    tempErrors.rewardTitle = rewardTitle ? "" : "Reward title is required";
    tempErrors.rewardDescription = rewardDescription ? "" : "Reward description is required";

    if (type && !["1", "2"].includes(type)) {
      tempErrors.type = "Invalid type selected";
    }
    if (discountAmount && isNaN(Number(discountAmount))) {
      tempErrors.discountAmount = "Discount amount must be a number";
    }
    if (maxDiscountAmount && isNaN(Number(maxDiscountAmount))) {
      tempErrors.maxDiscountAmount = "Max discount amount must be a number";
    }
    if (rewardPoints && !Number.isInteger(Number(rewardPoints))) {
      tempErrors.rewardPoints = "Reward points must be an integer";
    }
    if (type === "2") {
      if (allowedProducts.length === 0) {
        tempErrors.allowedProducts = "Please add at least one product/combo for this type";
      }
      if (!allowedProductsQuantity) {
        tempErrors.allowedProductsQuantity = "Allowed products quantity is required";
      } else if (!Number.isInteger(Number(allowedProductsQuantity)) || Number(allowedProductsQuantity) <= 0) {
        tempErrors.allowedProductsQuantity = "Allowed products quantity must be a positive integer";
      }
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const allowedProductsJson = type === "2" ? JSON.stringify({
        quantity: parseInt(allowedProductsQuantity),
        products_list: allowedProducts
      }) : null;

      const formData = new FormData();
      formData.append('type', type);
      formData.append('discount_amount', discountAmount);
      formData.append('is_percent', isPercent);
      formData.append('is_discount_taxable', isDiscountTaxable);
      formData.append('max_discount_amount', maxDiscountAmount);
      formData.append('discount_includes_modifiers', discountIncludesModifiers);
      formData.append('allowed_products', allowedProductsJson);
      formData.append('group_ids', JSON.stringify(groupIds.map(id => parseInt(id))));
      formData.append('reward_points', rewardPoints);
      formData.append('reward_title', rewardTitle);
      formData.append('reward_description', rewardDescription);

      if (picture) {
        formData.append('picture', picture);
      }

      try {
        const response = await updateReward(reward.id, formData);
        setMessage(response.message);
        resetForm();
        onClose(true);
      } catch (error) {
        console.error('Error updating loyalty reward:', error);
        setMessage('Failed to update loyalty reward');
      }
    } else {
      setMessage('Please correct the errors before submitting.');
    }
  };

  const handleClose = () => {
    resetForm();
    onClose(false);
  };

  const handleGroupChange = (event) => {
    setGroupIds(event.target.value);
  };

  const handleAddAllowedProduct = () => {
    if (selectedProduct && !allowedProducts.includes(selectedProduct)) {
      setAllowedProducts([...allowedProducts, selectedProduct]);
      setSelectedProduct('');
    }
  };

  const handleDeleteAllowedProduct = (product_id) => {
    setAllowedProducts(allowedProducts.filter(product => product !== product_id));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Loyalty Reward</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Edit the form below to update the loyalty reward.
        </DialogContentText>
        <FormControl fullWidth margin="normal" error={!!errors.type}>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="1">Order Discount</MenuItem>
            <MenuItem value="2">Product/Combo Discount</MenuItem>
          </Select>
          {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.discountAmount}>
          <TextField
            label="Discount Amount"
            value={discountAmount}
            onChange={(e) => setDiscountAmount(e.target.value)}
            error={!!errors.discountAmount}
            helperText={errors.discountAmount}
          />
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isPercent} onChange={(e) => setIsPercent(e.target.checked)} />}
            label="Is Percent"
          />
          <FormControlLabel
            control={<Checkbox checked={isDiscountTaxable} onChange={(e) => setIsDiscountTaxable(e.target.checked)} />}
            label="Is Discount Taxable"
          />
        </FormGroup>
        <FormControl fullWidth margin="normal" error={!!errors.maxDiscountAmount}>
          <TextField
            label="Max Discount Amount"
            value={maxDiscountAmount}
            onChange={(e) => setMaxDiscountAmount(e.target.value)}
            error={!!errors.maxDiscountAmount}
            helperText={errors.maxDiscountAmount}
          />
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={discountIncludesModifiers} onChange={(e) => setDiscountIncludesModifiers(e.target.checked)} />}
          label="Discount Includes Modifiers"
        />
        {type === '2' && (
          <>
            <FormControl fullWidth margin="normal" error={!!errors.allowedProducts}>
              <InputLabel>Allowed Products</InputLabel>
              <Select
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
              >
                {foodicsProducts.map((product) => (
                  <MenuItem key={product.product_id} value={product.product_id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
              <Button onClick={handleAddAllowedProduct}>Add Product</Button>
              {errors.allowedProducts && <FormHelperText>{errors.allowedProducts}</FormHelperText>}
            </FormControl>
            <List>
              {allowedProducts.map((productId) => {
                const product = foodicsProducts.find(p => p.product_id === productId);
                return (
                  <ListItem key={productId}>
                    <ListItemText primary={product ? product.name : productId} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => handleDeleteAllowedProduct(productId)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            <FormControl fullWidth margin="normal" error={!!errors.allowedProductsQuantity}>
              <TextField
                label="Allowed Products Quantity"
                value={allowedProductsQuantity}
                onChange={(e) => setAllowedProductsQuantity(e.target.value)}
                type="number"
                error={!!errors.allowedProductsQuantity}
                helperText={errors.allowedProductsQuantity}
              />
            </FormControl>
          </>
        )}
        <FormControl fullWidth margin="normal" error={!!errors.rewardPoints}>
          <TextField
            label="Reward Points"
            value={rewardPoints}
            onChange={(e) => setRewardPoints(e.target.value)}
            type="number"
            error={!!errors.rewardPoints}
            helperText={errors.rewardPoints}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography variant="subtitle1" gutterBottom>
            Reward Picture
          </Typography>
          <ImageUpload
            onChange={(e) => {
              if (e.target.files[0]) {
                setPicture(e.target.files[0]);
                setPicturePreview(URL.createObjectURL(e.target.files[0]));
              }
            }}
            error={errors.picture}
            value={picture}
          />
          {(picturePreview || reward.picture) && (
            <Box mt={2}>
              <Typography variant="subtitle2">Current Picture:</Typography>
              {picture ? (
                <img src={picturePreview} alt="Reward preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
              ) : (
                <AuthenticatedImage 
                  src={reward.picture}
                  alt="Reward preview"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
              )}
            </Box>
          )}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.rewardTitle}>
          <TextField
            label="Reward Title"
            value={rewardTitle}
            onChange={(e) => setRewardTitle(e.target.value)}
            error={!!errors.rewardTitle}
            helperText={errors.rewardTitle}
          />
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.rewardDescription}>
          <TextField
            label="Reward Description"
            value={rewardDescription}
            onChange={(e) => setRewardDescription(e.target.value)}
            multiline
            rows={4}
            error={!!errors.rewardDescription}
            helperText={errors.rewardDescription}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Assign to Groups</InputLabel>
          <Select
            multiple
            value={groupIds}
            onChange={handleGroupChange}
            renderValue={(selected) => selected.map(id => groups.find(g => g.id === id)?.group_name).join(', ')}
          >
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                <Checkbox checked={groupIds.indexOf(group.id) > -1} />
                <ListItemText primary={group.group_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Update Loyalty Reward
        </Button>
      </DialogActions>
      {message && (
        <Typography color="error" align="center" style={{ marginBottom: '10px' }}>
          {message}
        </Typography>
      )}
    </Dialog>
  );
};

export default EditLoyaltyReward;