import React from 'react';
import {
  FormControl,
  Button,
  Box,
  Typography
} from '@mui/material';

const ImageUpload = ({ onChange, error, value }) => {
  return (
    <FormControl fullWidth margin="normal" error={!!error}>
      <Typography variant="subtitle1" gutterBottom>
        Image
      </Typography>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={onChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="outlined" component="span">
          Browse
        </Button>
      </label>
      {value && (
        <Box mt={1}>
          <Typography variant="body2">
            Selected file: {value.name}
          </Typography>
        </Box>
      )}
      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default ImageUpload;