import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  FormHelperText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { addLoyaltyReward, fetchEveryCustomerGroups, fetchFoodicsMenu } from '../services/api';

const AddLoyaltyReward = ({ open, onClose }) => {
  const [type, setType] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [isPercent, setIsPercent] = useState(false);
  const [isDiscountTaxable, setIsDiscountTaxable] = useState(false);
  const [maxDiscountAmount, setMaxDiscountAmount] = useState('');
  const [discountIncludesModifiers, setDiscountIncludesModifiers] = useState(false);
  const [allowedProducts, setAllowedProducts] = useState([]);
  const [allowedProductsQuantity, setAllowedProductsQuantity] = useState('');
  const [groupIds, setGroupIds] = useState([]);
  const [groups, setGroups] = useState([]);
  const [rewardPoints, setRewardPoints] = useState('');
  const [picture, setPicture] = useState(null);
  const [rewardTitle, setRewardTitle] = useState('');
  const [rewardDescription, setRewardDescription] = useState('');
  const [message, setMessage] = useState('');
  const [foodicsProducts, setFoodicsProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const data = await fetchEveryCustomerGroups();
        setGroups(data);
      } catch (err) {
        console.error('Failed to load groups:', err);
      }
    };

    const loadFoodicsProducts = async () => {
      try {
        const data = await fetchFoodicsMenu();
        setFoodicsProducts(data);
      } catch (err) {
        console.error('Failed to load Foodics products:', err);
      }
    };

    loadGroups();
    loadFoodicsProducts();
  }, []);

  const resetForm = () => {
    setType('');
    setDiscountAmount('');
    setIsPercent(false);
    setIsDiscountTaxable(false);
    setMaxDiscountAmount('');
    setDiscountIncludesModifiers(false);
    setAllowedProducts([]);
    setAllowedProductsQuantity('');
    setGroupIds([]);
    setRewardPoints('');
    setPicture(null);
    setRewardTitle('');
    setRewardDescription('');
    setMessage('');
    setSelectedProduct('');
    setErrors({});
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.type = type ? "" : "Type is required";
    tempErrors.discountAmount = discountAmount ? "" : "Discount amount is required";
    tempErrors.maxDiscountAmount = maxDiscountAmount ? "" : "Max discount amount is required";
    tempErrors.rewardPoints = rewardPoints ? "" : "Reward points are required";
    tempErrors.rewardTitle = rewardTitle ? "" : "Reward title is required";
    tempErrors.rewardDescription = rewardDescription ? "" : "Reward description is required";

    if (type && !["1", "2"].includes(type)) {
      tempErrors.type = "Invalid type selected";
    }
    if (discountAmount && isNaN(Number(discountAmount))) {
      tempErrors.discountAmount = "Discount amount must be a number";
    }
    if (maxDiscountAmount && isNaN(Number(maxDiscountAmount))) {
      tempErrors.maxDiscountAmount = "Max discount amount must be a number";
    }
    if (rewardPoints && !Number.isInteger(Number(rewardPoints))) {
      tempErrors.rewardPoints = "Reward points must be an integer";
    }
    if (type === "2") {
      if (allowedProducts.length === 0) {
        tempErrors.allowedProducts = "Please add at least one product/combo for this type";
      }
      if (!allowedProductsQuantity) {
        tempErrors.allowedProductsQuantity = "Allowed products quantity is required";
      } else if (!Number.isInteger(Number(allowedProductsQuantity)) || Number(allowedProductsQuantity) <= 0) {
        tempErrors.allowedProductsQuantity = "Allowed products quantity must be a positive integer";
      }
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validate()) {
      const formData = new FormData();
      formData.append('type', type);
      formData.append('discount_amount', discountAmount);
      formData.append('is_percent', isPercent);
      formData.append('is_discount_taxable', isDiscountTaxable);
      formData.append('max_discount_amount', maxDiscountAmount);
      formData.append('discount_includes_modifiers', discountIncludesModifiers);
      formData.append('allowed_products', JSON.stringify(type === "2" ? {
        quantity: parseInt(allowedProductsQuantity),
        products_list: allowedProducts
      } : null));
      
      // Convert groupIds to integers and append as JSON string
      const groupIdsArray = groupIds.map(id => parseInt(id));
      formData.append('group_ids', JSON.stringify(groupIdsArray));
      
      formData.append('reward_points', rewardPoints);
      formData.append('reward_title', rewardTitle);
      formData.append('reward_description', rewardDescription);
      formData.append('set_active', true); // Assuming new rewards are active by default
  
      if (picture) {
        formData.append('picture', picture);
      }
  
      try {
        const response = await addLoyaltyReward(formData);
        setMessage(response.message);
        resetForm();
        onClose(true);
      } catch (error) {
        console.error('Error adding loyalty reward:', error);
        setMessage('Failed to add loyalty reward');
      }
    } else {
      setMessage('Please correct the errors before submitting.');
    }
  };

  const handleClose = () => {
    resetForm();
    onClose(false);
  };

  const handleGroupChange = (event) => {
    setGroupIds(event.target.value);
  };

  const handleAddAllowedProduct = () => {
    if (selectedProduct && !allowedProducts.includes(selectedProduct)) {
      setAllowedProducts([...allowedProducts, selectedProduct]);
      setSelectedProduct('');
    }
  };

  const handleDeleteAllowedProduct = (product_id) => {
    setAllowedProducts(allowedProducts.filter(product => product !== product_id));
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Loyalty Reward</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill out the form below to add a new loyalty reward.
        </DialogContentText>
        <FormControl fullWidth margin="normal" error={!!errors.type}>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="1">Order Discount</MenuItem>
            <MenuItem value="2">Product/Combo Discount</MenuItem>
          </Select>
          {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.discountAmount}>
          <TextField
            label="Discount Amount"
            value={discountAmount}
            onChange={(e) => setDiscountAmount(e.target.value)}
            error={!!errors.discountAmount}
            helperText={errors.discountAmount}
          />
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isPercent} onChange={(e) => setIsPercent(e.target.checked)} />}
            label="Is Percent"
          />
          <FormControlLabel
            control={<Checkbox checked={isDiscountTaxable} onChange={(e) => setIsDiscountTaxable(e.target.checked)} />}
            label="Is Discount Taxable"
          />
        </FormGroup>
        <FormControl fullWidth margin="normal" error={!!errors.maxDiscountAmount}>
          <TextField
            label="Max Discount Amount"
            value={maxDiscountAmount}
            onChange={(e) => setMaxDiscountAmount(e.target.value)}
            error={!!errors.maxDiscountAmount}
            helperText={errors.maxDiscountAmount}
          />
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={discountIncludesModifiers} onChange={(e) => setDiscountIncludesModifiers(e.target.checked)} />}
          label="Discount Includes Modifiers"
        />
        {type === '2' && (
          <>
            <FormControl fullWidth margin="normal" error={!!errors.allowedProducts}>
              <InputLabel>Allowed Products</InputLabel>
              <Select
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
              >
                {foodicsProducts.map((product) => (
                  <MenuItem key={product.product_id} value={product.product_id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
              <Button onClick={handleAddAllowedProduct}>Add Product</Button>
              {errors.allowedProducts && <FormHelperText>{errors.allowedProducts}</FormHelperText>}
            </FormControl>
            <List>
              {allowedProducts.map((productId) => (
                <ListItem key={productId}>
                  <ListItemText primary={foodicsProducts.find(p => p.product_id === productId)?.name || productId} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleDeleteAllowedProduct(productId)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <FormControl fullWidth margin="normal" error={!!errors.allowedProductsQuantity}>
              <TextField
                label="Allowed Products Quantity"
                value={allowedProductsQuantity}
                onChange={(e) => setAllowedProductsQuantity(e.target.value)}
                type="number"
                error={!!errors.allowedProductsQuantity}
                helperText={errors.allowedProductsQuantity}
              />
            </FormControl>
          </>
        )}
        <FormControl fullWidth margin="normal" error={!!errors.rewardPoints}>
          <TextField
            label="Reward Points"
            value={rewardPoints}
            onChange={(e) => setRewardPoints(e.target.value)}
            type="number"
            error={!!errors.rewardPoints}
            helperText={errors.rewardPoints}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel shrink>Picture</InputLabel>
          <input
            accept="image/*"
            type="file"
            onChange={handleImageChange}
          />
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.rewardTitle}>
          <TextField
            label="Reward Title"
            value={rewardTitle}
            onChange={(e) => setRewardTitle(e.target.value)}
            error={!!errors.rewardTitle}
            helperText={errors.rewardTitle}
          />
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.rewardDescription}>
          <TextField
            label="Reward Description"
            value={rewardDescription}
            onChange={(e) => setRewardDescription(e.target.value)}
            multiline
            rows={4}
            error={!!errors.rewardDescription}
            helperText={errors.rewardDescription}
          />
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.groupIds}>
          <InputLabel>Assign to Groups</InputLabel>
          <Select
            multiple
            value={groupIds}
            onChange={handleGroupChange}
            renderValue={(selected) => selected.map(id => groups.find(g => g.id === id)?.group_name).join(', ')}
          >
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                <Checkbox checked={groupIds.indexOf(group.id) > -1} />
                <ListItemText primary={group.group_name} />
              </MenuItem>
            ))}
          </Select>
          {errors.groupIds && <FormHelperText>{errors.groupIds}</FormHelperText>}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Add Loyalty Reward
        </Button>
      </DialogActions>
      {message && (
        <Typography color="error" align="center" style={{ marginBottom: '10px' }}>
          {message}
        </Typography>
      )}
    </Dialog>
  );
};

export default AddLoyaltyReward;
