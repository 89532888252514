import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Alert,
  Button,
  Box,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { fetchSpecialHomeItem, updateSpecialHomeItem, fetchAuthenticatedImage } from '../services/api';
import ImageUpload from './ImageUpload';
import AuthenticatedImage from './AuthenticatedImage';

const ViewSpecialHome = () => {
  const [specialItem, setSpecialItem] = useState(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isSpecialItemEditDialogOpen, setIsSpecialItemEditDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const loadSpecialHomeItem = async () => {
      try {
        const data = await fetchSpecialHomeItem();
        setSpecialItem(data);
      } catch (err) {
        console.error('Error fetching special home item:', err);
        setError(err);
      }
    };

    loadSpecialHomeItem();
  }, []);

  const handleViewClick = () => {
    setIsViewDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
  };

  const handleSpecialItemEditClick = () => {
    const { updated_at, created_at, id, ...editableItem } = specialItem;
    setEditItem(editableItem);
    setNewImage(null);
    setErrors({});
    setMessage('');
    setIsSpecialItemEditDialogOpen(true);
  };

  const validateFields = () => {
    const newErrors = {};
    Object.entries(editItem).forEach(([key, value]) => {
      if (key !== 'image' && value.trim() === '') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      }
    });
    return newErrors;
  };

  const handleSpecialItemEditDialogClose = async (save = false) => {
    if (save) {
      const newErrors = validateFields();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      setIsLoading(true);
      try {
        const formData = new FormData();
        Object.entries(editItem).forEach(([key, value]) => {
          formData.append(key, value);
        });
        if (newImage) {
          formData.append('image', newImage);
        }
        await updateSpecialHomeItem(formData);
        const data = await fetchSpecialHomeItem();
        setSpecialItem(data);
        setMessage('Successfully updated special home item');
        setIsSpecialItemEditDialogOpen(false);
      } catch (err) {
        console.error('Error updating special home item:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSpecialItemEditDialogOpen(false);
    }
    setEditItem(null);
    setNewImage(null);
    setErrors({});
    setMessage('');
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setNewImage(e.target.files[0]);
      if (errors.image) {
        setErrors((prev) => ({ ...prev, image: '' }));
      }
    }
  };


  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error.message}
        </Alert>
      )}
      <Card>
        <CardHeader title="Special Home Item" />
        <CardContent>
          {specialItem ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Subtitle</TableCell>
                    <TableCell>Content</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>View</TableCell>
                    <TableCell>Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{specialItem.title}</TableCell>
                    <TableCell>{specialItem.subtitle}</TableCell>
                    <TableCell>{specialItem.content}</TableCell>
                    <TableCell>
                      {specialItem.image && (
                        <AuthenticatedImage 
                          src={specialItem.image}
                          alt={specialItem.title} 
                          style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} 
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={handleViewClick} sx={{ color: '#1976D2' }}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={handleSpecialItemEditClick} sx={{ color: 'green' }}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <CircularProgress />
          )}
        </CardContent>
      </Card>

      <Dialog open={isViewDialogOpen} onClose={handleViewDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>View Special Home Item</DialogTitle>
        <DialogContent>
          {specialItem && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Title:</Typography>
              <Typography paragraph>{specialItem.title}</Typography>
              
              <Typography variant="h6">Subtitle:</Typography>
              <Typography paragraph>{specialItem.subtitle}</Typography>
              
              <Typography variant="h6">Content:</Typography>
              <Typography paragraph>{specialItem.content}</Typography>
              
              {specialItem.image && (
                <Box mt={2}>
                  <Typography variant="h6">Image:</Typography>
                  <AuthenticatedImage 
                    src={specialItem.image}
                    alt={specialItem.title}
                    style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }}
                  />
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isSpecialItemEditDialogOpen} onClose={() => handleSpecialItemEditDialogClose(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Special Home Item</DialogTitle>
        <DialogContent>
          {editItem && (
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
              {Object.entries(editItem).map(([key, value]) => (
                key !== 'image' && (
                  <TextField
                    key={key}
                    margin="dense"
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    name={key}
                    type="text"
                    fullWidth
                    multiline={key === 'content'}
                    rows={key === 'content' ? 4 : 1}
                    value={value}
                    onChange={handleEditInputChange}
                    error={!!errors[key]}
                    helperText={errors[key]}
                    variant="outlined"
                  />
                )
              ))}
              <ImageUpload
                onChange={handleImageChange}
                error={errors.image}
                value={newImage}
              />
              {newImage && (
                <Box mt={2}>
                  <Typography variant="subtitle1">New Image Preview:</Typography>
                  <img 
                    src={URL.createObjectURL(newImage)} 
                    alt="New image preview" 
                    style={{ maxWidth: '100%', marginTop: '10px' }} 
                  />
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSpecialItemEditDialogClose(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleSpecialItemEditDialogClose(true)} color="primary" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
        {message && (
          <Box sx={{ p: 2 }}>
            <Alert severity="success">{message}</Alert>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default ViewSpecialHome;