import React, { useState, useEffect } from 'react';
import { fetchAuthenticatedImage } from '../services/api';

const AuthenticatedImage = ({ src, alt, style }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageObjectURL = await fetchAuthenticatedImage(src);
        setImageSrc(imageObjectURL);
      } catch (error) {
        console.error('Error loading image:', error);
      }
    };

    if (src) {
      loadImage();
    }

    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [src]);

  if (!imageSrc) {
    return null; // Or you could return a placeholder image
  }

  return <img src={imageSrc} alt={alt} style={style} />;
};

export default AuthenticatedImage;