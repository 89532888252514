import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControl,
} from '@mui/material';
import { addCustomerGroup } from '../services/api';

const AddCustomerGroup = ({ open, onClose }) => {
  const [groupName, setGroupName] = useState('');
  const [message, setMessage] = useState('');

  const handleSave = async () => {
    try {
      const newGroup = { group_name: groupName };
      await addCustomerGroup(newGroup);
      setMessage('Customer group added successfully');
      onClose(true);
    } catch (error) {
      setMessage('Error adding customer group');
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Add Customer Group</DialogTitle>
      <DialogContent>
        <DialogContentText>Fill out the form below to add a new customer group.</DialogContentText>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter group name"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="secondary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Add Group</Button>
      </DialogActions>
      {message && <DialogContentText>{message}</DialogContentText>}
    </Dialog>
  );
};

export default AddCustomerGroup;
