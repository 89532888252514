// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './components/Navigation';
import AppRoutes from './Routes';
import Cookies from 'js-cookie';
import TokenHandler from './services/TokenHandler';

const App = () => {
  const [token, setToken] = useState(Cookies.get('token'));

  const handleLogin = (token) => {
    setToken(token);
    Cookies.set('token', token, {
      expires: 0.25,
      secure: true,
      sameSite: 'Strict',
    }); // Set token to expire in 15 minutes
  };

  const handleLogout = () => {
    setToken(null);
    Cookies.remove('token', { secure: true, sameSite: 'Strict' });
    Cookies.remove('refreshToken', { secure: true, sameSite: 'Strict' });
  };

  return (
    <Router>
      <TokenHandler handleLogout={handleLogout}>
        <div style={{ display: 'flex' }}>
          {token && <Navigation handleLogout={handleLogout} />}
          <main style={{ flexGrow: 1, padding: '24px' }}>
            <AppRoutes token={token} handleLogin={handleLogin} />
          </main>
        </div>
      </TokenHandler>
    </Router>
  );
};

export default App;
