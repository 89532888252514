import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { redeemReward } from '../services/api';

const RedeemReward = () => {
  const [rewardData, setRewardData] = useState({
    reward_code: '',
    branch_id: '',
    business_reference: '',
    discount_amount: '',
    mobile_country_code: '',
    customer_mobile_number: '',
    date: '',
    user_id: '',
    order_id: '',
    redeemed_combos: '',
    redeemed_products: ''
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRewardData({
      ...rewardData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        ...rewardData,
        discount_amount: parseFloat(rewardData.discount_amount),
        date: new Date(rewardData.date).toISOString(),
        redeemed_combos: rewardData.redeemed_combos ? JSON.parse(rewardData.redeemed_combos) : null,
        redeemed_products: rewardData.redeemed_products ? JSON.parse(rewardData.redeemed_products) : null
      };
      const response = await redeemReward(data);
      setMessage(response.message);
    } catch (error) {
      setMessage('Failed to redeem reward');
    }
  };

  return (
    <Card className="mt-4">
      <Card.Header as="h2">Redeem Reward</Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formRewardCode">
            <Form.Label>Reward Code</Form.Label>
            <Form.Control
              type="text"
              name="reward_code"
              value={rewardData.reward_code}
              onChange={handleChange}
              placeholder="Enter reward code"
            />
          </Form.Group>

          <Form.Group controlId="formBranchId">
            <Form.Label>Branch ID</Form.Label>
            <Form.Control
              type="text"
              name="branch_id"
              value={rewardData.branch_id}
              onChange={handleChange}
              placeholder="Enter branch ID"
            />
          </Form.Group>

          <Form.Group controlId="formBusinessReference">
            <Form.Label>Business Reference</Form.Label>
            <Form.Control
              type="text"
              name="business_reference"
              value={rewardData.business_reference}
              onChange={handleChange}
              placeholder="Enter business reference"
            />
          </Form.Group>

          <Form.Group controlId="formDiscountAmount">
            <Form.Label>Discount Amount</Form.Label>
            <Form.Control
              type="text"
              name="discount_amount"
              value={rewardData.discount_amount}
              onChange={handleChange}
              placeholder="Enter discount amount"
            />
          </Form.Group>

          <Form.Group controlId="formMobileCountryCode">
            <Form.Label>Mobile Country Code</Form.Label>
            <Form.Control
              type="text"
              name="mobile_country_code"
              value={rewardData.mobile_country_code}
              onChange={handleChange}
              placeholder="Enter mobile country code"
            />
          </Form.Group>

          <Form.Group controlId="formCustomerMobileNumber">
            <Form.Label>Customer Mobile Number</Form.Label>
            <Form.Control
              type="text"
              name="customer_mobile_number"
              value={rewardData.customer_mobile_number}
              onChange={handleChange}
              placeholder="Enter customer mobile number"
            />
          </Form.Group>

          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="datetime-local"
              name="date"
              value={rewardData.date}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formUserId">
            <Form.Label>User ID</Form.Label>
            <Form.Control
              type="text"
              name="user_id"
              value={rewardData.user_id}
              onChange={handleChange}
              placeholder="Enter user ID"
            />
          </Form.Group>

          <Form.Group controlId="formOrderId">
            <Form.Label>Order ID</Form.Label>
            <Form.Control
              type="text"
              name="order_id"
              value={rewardData.order_id}
              onChange={handleChange}
              placeholder="Enter order ID"
            />
          </Form.Group>

          <Form.Group controlId="formRedeemedCombos">
            <Form.Label>Redeemed Combos (JSON format)</Form.Label>
            <Form.Control
              type="text"
              name="redeemed_combos"
              value={rewardData.redeemed_combos}
              onChange={handleChange}
              placeholder='Enter redeemed combos as JSON, e.g., [{"quantity": 1, "id": "combo1"}, {"quantity": 1, "id": "combo2"}]'
            />
          </Form.Group>

          <Form.Group controlId="formRedeemedProducts">
            <Form.Label>Redeemed Products (JSON format)</Form.Label>
            <Form.Control
              type="text"
              name="redeemed_products"
              value={rewardData.redeemed_products}
              onChange={handleChange}
              placeholder='Enter redeemed products as JSON, e.g., [{"quantity": 1, "id": "product1"}, {"quantity": 1, "id": "product2"}]'
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Redeem Reward
          </Button>
        </Form>
        {message && <p className="mt-3">{message}</p>}
      </Card.Body>
    </Card>
  );
};

export default RedeemReward;
