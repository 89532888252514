import React from 'react';
import { Container } from '@mui/material';
import ViewSpecialHome from './ViewSpecialHome';
import ViewHomeItems from './ViewHomeItems';

const AppHomeScreen = () => {
  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <ViewSpecialHome />
      <ViewHomeItems />
    </Container>
  );
};

export default AppHomeScreen;
