import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  TablePagination,
  Alert,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fetchCustomerRewardTransactions } from '../services/api';

const RewardTransactionsTable = ({ customerMobileNumber }) => {
  const [rewardCodes, setRewardCodes] = useState([]);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [currentRewardCode, setCurrentRewardCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const loadRewardCodes = async (mobileNumber, page, rowsPerPage) => {
    setIsLoading(true);
    try {
      const data = await fetchCustomerRewardTransactions(mobileNumber, page + 1, rowsPerPage);
      setRewardCodes(data.rewardCodes);
      setTotalRecords(data.totalRecords);
    } catch (err) {
      console.error('Error fetching reward codes:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customerMobileNumber) {
      loadRewardCodes(customerMobileNumber, page, rowsPerPage);
    }
  }, [customerMobileNumber, page, rowsPerPage]);

  const handleViewClick = (rewardCode) => {
    setCurrentRewardCode(rewardCode);
    setIsViewDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentRewardCode(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper sx={{ mt: 4, p: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error.message}
        </Alert>
      )}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Reward Code</TableCell>
                  <TableCell>Redeem Status</TableCell>
                  <TableCell>Used Status</TableCell>
                  <TableCell>Reward Points</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rewardCodes.map((rewardCode) => (
                  <TableRow key={rewardCode.reward_code}>
                    <TableCell>{rewardCode.reward_code}</TableCell>
                    <TableCell>{rewardCode.redeem_status ? 'Redeemed' : 'Not Redeemed'}</TableCell>
                    <TableCell>{rewardCode.used_status ? 'Used' : 'Not Used'}</TableCell>
                    <TableCell>{rewardCode.reward_points}</TableCell>
                    <TableCell>{new Date(rewardCode.createdAt).toLocaleString()}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewClick(rewardCode)} sx={{ color: '#1976D2' }}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </>
      )}
    </Paper>
  );
};

export default RewardTransactionsTable;
