import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  TablePagination,
  Button,
} from '@mui/material';
import { fetchAllCustomerGroups } from '../services/api';
import AddCustomerGroup from './AddCustomerGroup';

const ViewCustomerGroups = () => {
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isAddGroupDialogOpen, setIsAddGroupDialogOpen] = useState(false);

  const loadGroups = async (page, rowsPerPage) => {
    setIsLoading(true);
    try {
      const data = await fetchAllCustomerGroups(page + 1, rowsPerPage);
      setGroups(data.groups);
      setTotalRecords(data.totalRecords);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadGroups(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openAddGroupDialog = () => {
    setIsAddGroupDialogOpen(true);
  };

  const closeAddGroupDialog = (updated) => {
    setIsAddGroupDialogOpen(false);
    if (updated) {
      loadGroups(page, rowsPerPage);
    }
  };

  return (
    <Paper sx={{ mt: 4, p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Customer Groups
      </Typography>
      <Button variant="contained" color="primary" onClick={openAddGroupDialog} sx={{ mb: 2 }}>
        Add New Group
      </Button>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">Error fetching groups: {error.message}</Typography>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Group Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((group) => (
                  <TableRow key={group.id}>
                    <TableCell>{group.id}</TableCell>
                    <TableCell>{group.group_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </>
      )}
      <AddCustomerGroup open={isAddGroupDialogOpen} onClose={closeAddGroupDialog} />
    </Paper>
  );
};

export default ViewCustomerGroups;
