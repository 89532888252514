import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  FormControl,
} from '@mui/material';
import { addHomeItem } from '../services/api';
import ImageUpload from './ImageUpload';

const AddHomeItem = ({ open, onClose, onAddItem }) => {
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    content: '',
  });
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');

  const validateFields = () => {
    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (value.trim() === '') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      }
    });
    if (!image) {
      newErrors.image = 'Image is required.';
    }
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      if (errors.image) {
        setErrors((prev) => ({ ...prev, image: '' }));
      }
    }
  };

  const resetForm = () => {
    setFormData({
      title: '',
      subtitle: '',
      content: '',
    });
    setImage(null);
    setErrors({});
    setMessage('');
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleAddItem = async () => {
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    formDataToSend.append('image', image);

    try {
      const response = await addHomeItem(formDataToSend);
      if (response && response.item) {
        setMessage('Successfully added home item');
        onAddItem();
        handleClose();
      } else {
        setMessage('Failed to add home item');
      }
    } catch (err) {
      console.error('Error adding home item:', err);
      setMessage('Failed to add home item');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Home Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill out the form below to add a new home item.
        </DialogContentText>
        {Object.entries(formData).map(([key, value]) => (
          <FormControl key={key} fullWidth margin="normal">
            <TextField
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              name={key}
              value={value}
              onChange={handleInputChange}
              placeholder={`Enter item ${key}`}
              multiline={key === 'content'}
              rows={key === 'content' ? 4 : 1}
              error={!!errors[key]}
              helperText={errors[key]}
            />
          </FormControl>
        ))}
        <ImageUpload
          onChange={handleImageChange}
          error={errors.image}
          value={image}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAddItem} color="primary">
          Add Home Item
        </Button>
      </DialogActions>
      {message && <DialogContentText style={{ padding: '0 24px 20px' }}>{message}</DialogContentText>}
    </Dialog>
  );
};

export default AddHomeItem;