import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { refreshToken as refreshAuthToken } from './auth';
import { API_URL, Direct_API_URL, public_URL } from '../config';


// Create an instance of axios
const api = axios.create({
  baseURL: API_URL,
});

// Set up request interceptor to add the Authorization header
api.interceptors.request.use((config) => {
  const token = Cookies.get('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));

// Set up response interceptor to handle token refresh and redirection
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 || error.response.status === 403) {
      try {
        const refreshToken = Cookies.get('refreshToken');
        if (refreshToken) {
          const { data } = await axios.post(`${API_URL}/refresh`, { token: refreshToken });
          Cookies.set('token', data.accessToken, { expires: 0.25 });
          originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
          return api(originalRequest);
        } else {
          window.location.href = '/login'; // Redirect to login if no refresh token
          return Promise.reject(error);
        }
      } catch (refreshError) {
        console.error('Refresh token error:', refreshError);
        window.location.href = '/login'; // Redirect to login on refresh error
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export const fetchMenuItems = async () => {
  try {
    const response = await api.get('/menu');
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items:', error);
    throw error;
  }
};

// Add a new menu item
export const addMenuItem = async (data) => {
  try {
    const response = await api.post('/menu/add', data);
    return response.data;
  } catch (error) {
    console.error('Error adding menu item:', error);
    throw error;
  }
};

// Delete a menu item
export const deleteMenuItem = async (itemId) => {
  try {
    const response = await api.delete(`/menu/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting menu item:', error);
    throw error;
  }
};

// Fetch a specific menu item by ID
export const fetchMenuItemById = async (itemId) => {
  try {
    const response = await api.get(`/menu/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching menu item by ID:', error);
    throw error;
  }
};

// Update a menu item
export const updateMenuItem = async (itemId, data) => {
  try {
    const response = await api.put(`/menu/${itemId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating menu item:', error);
    throw error;
  }
};

// Fetch all home items
export const fetchHomeItems = async () => {
  try {
    const response = await api.get('/home-items');
    return response.data;
  } catch (error) {
    console.error('Error fetching home items:', error);
    throw error;
  }
};

// Add a new home item
export const addHomeItem = async (data) => {
  try {
    const response = await api.post('/home-items/add', data);
    return response.data;
  } catch (error) {
    console.error('Error adding home item:', error);
    throw error;
  }
};

// Delete a home item
export const deleteHomeItem = async (itemId) => {
  try {
    const response = await api.delete(`/home-items/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting home item:', error);
    throw error;
  }
};

// Fetch a specific home item by ID
export const fetchHomeItemById = async (itemId) => {
  try {
    const response = await api.get(`/home-items/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching home item by ID:', error);
    throw error;
  }
};

// Update a home item
export const updateHomeItem = async (itemId, data) => {
  try {
    const response = await api.put(`/home-items/${itemId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating home item:', error);
    throw error;
  }
};

// Fetch special home item
export const fetchSpecialHomeItem = async () => {
  try {
    const response = await api.get('/special-home-item');
    return response.data;
  } catch (error) {
    console.error('Error fetching special home item:', error);
    throw error;
  }
};

// Update special home item
export const updateSpecialHomeItem = async (item) => {
  try {
    const response = await api.put('/special-home-item', item);
    return response.data;
  } catch (error) {
    console.error('Error updating special home item:', error);
    throw error;
  }
};

// Add other API methods similarly

export const fetchDiscounts = async () => {
  try {
    const response = await api.get('/discounts');
    return response.data;
  } catch (error) {
    console.error('Error fetching discounts:', error);
    throw error;
  }
};

export const fetchRewards = async () => {
  try {
    const response = await api.get('/rewards');
    return response.data;
  } catch (error) {
    console.error('Error fetching rewards:', error);
    throw error;
  }
};

export const fetchGroupIdsForReward = async (rewardId) => {
  try {
    const response = await api.get(`/loyalty/reward/${rewardId}/groups`);
    return response.data;
  } catch (error) {
    console.error('Error fetching group IDs for reward:', error);
    throw error;
  }
};

export const checkReward = async (data) => {
  try {
    const response = await api.post('/loyalty/reward', data);
    return response.data;
  } catch (error) {
    console.error('Error checking reward:', error);
    throw error;
  }
};

export const redeemReward = async (data) => {
  try {
    const response = await api.post('/loyalty/redeem', data);
    return response.data;
  } catch (error) {
    console.error('Error redeeming reward:', error);
    throw error;
  }
};

export const addReward = async (data) => {
  try {
    const response = await api.post('/loyalty/add', data);
    return response.data;
  } catch (error) {
    console.error('Error adding reward:', error);
    throw error;
  }
};

export const updateReward = async (rewardId, data) => {
  try {
    const response = await api.put(`/loyalty/${rewardId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating reward:', error);
    throw error;
  }
};

export const deleteReward = async (rewardId) => {
  try {
    const response = await api.delete(`/loyalty/${rewardId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting reward:', error);
    throw error;
  }
};

export const submitOrder = async (order) => {
  try {
    const response = await api.post('/orders', { items: order });
    return response.data;
  } catch (error) {
    console.error('Error submitting order:', error);
    throw error;
  }
};

export const fetchAllRewards = async () => {
  try {
    const response = await api.get('/loyalty/all');
    return response.data;
  } catch (error) {
    console.error('Error fetching all rewards:', error);
    throw error;
  }
};

export const fetchBusinessRefs = async () => {
  try {
    const response = await api.get('/loyalty/business-reference');
    return response.data;
  } catch (error) {
    console.error('Error fetching all Business References:', error);
    throw error;
  }
};

export const deleteBusinessRef = async (id) => {
  try {
    await api.delete(`/loyalty/business-reference/${id}`);
  } catch (error) {
    console.error('Error deleting Business Reference:', error);
    throw error;
  }
};

export const updateBusinessRef = async (id, data) => {
  try {
    const response = await api.put(`/loyalty/business-reference/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating Business Reference:', error);
    throw error;
  }
};

export const fetchAllCustomers = async (page = 1, pageSize = 10, search = '') => {
  try {
    const response = await api.get('/customers', {
      params: { page, pageSize, search },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching customers:', error);
    throw error;
  }
};


export const addCustomerGroup = async (data) => {
  try {
    const response = await api.post('/customer-groups/add', data);
    return response.data;
  } catch (error) {
    console.error('Error adding customer group:', error);
    throw error;
  }
};

export const addCustomerToGroup = async (data) => {
  try {
    const response = await api.post('/customer-groups/add-customer', data);
    return response.data;
  } catch (error) {
    console.error('Error adding customer to group:', error);
    throw error;
  }
};

export const fetchCustomerGroups = async (customer_mobile_number, mobile_country_code) => {
  try {
    const response = await api.get(`/customer-groups/customer-groups/${customer_mobile_number}/${mobile_country_code}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching customer groups:', error);
    throw error;
  }
};

export const addCustomer = async (data) => {
  try {
    const response = await api.post('/customers/add', data);
    return response.data;
  } catch (error) {
    console.error('Error adding customer:', error);
    throw error;
  }
};

export const updateCustomerGroup = async (data) => {
  try {
    const response = await api.post('/customers/update-group', data);
    return response.data;
  } catch (error) {
    console.error('Error updating customer group:', error);
    throw error;
  }
};

export const removeCustomerFromGroup = async (data) => {
  try {
    const response = await api.delete('/customer-groups/remove-customer', { data });
    return response.data;
  } catch (error) {
    console.error('Error removing customer from group:', error);
    throw error;
  }
};

//with pagination 
export const fetchAllCustomerGroups = async (page, rowsPerPage) => {
  try {
    const response = await api.get('/customer-groups', {
      params: { page, pageSize: rowsPerPage },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer groups:', error);
    throw error;
  }
};

//without pagination 
export const fetchEveryCustomerGroups = async (data) => {
  try {
    const response = await api.get('/customer-groups/all', data);
    return response.data;
  } catch (error) {
    console.error('Error fetching customer groups:', error);
    throw error;
  }
};


export const addLoyaltyReward = async (data) => {
  try {
    const response = await api.post('/loyalty/add', data);
    return response.data;
  } catch (error) {
    console.error('Error adding loyalty reward:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchGroupLoyaltyRewards = async (groupId) => {
  try {
    const response = await api.get(`/loyalty/group/${groupId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching group loyalty rewards:', error);
    throw error;
  }
};

export const fetchCustomerLoyaltyRewards = async (data) => {
  try {
    const response = await api.post('/loyalty/customer-rewards', data);
    return response.data;
  } catch (error) {
    console.error('Error fetching customer loyalty rewards:', error);
    throw error;
  }
};

export const fetchFoodicsProducts = async () => {
  try {
    const response = await api.get('/foodics/products');
    return response.data;
  } catch (error) {
    console.error('Error fetching Foodics products:', error);
    throw error;
  }
};

export const fetchFoodicsMenu = async () => {
  try {
    const response = await api.get('/foodics/foodicsmenu');
    return response.data;
  } catch (error) {
    console.error('Error fetching foodics menu:', error);
    throw error;
  }
};

export const updateFoodicsProductImage = async (id, data) => {
  try {
    const response = await api.put(`/foodics/update-product-image/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating product image:', error);
    throw error;
  }
};

export const syncFoodicsMenu = async () => {
  try {
    const response = await api.get('/foodics/sync-foodicsmenu');
    return response.data;
  } catch (error) {
    console.error('Error syncing Foodics menu:', error);
    throw error;
  }
};

// New function for toggling reward active status
export const toggleRewardActive = async (rewardId, setActive) => {
  try {
    const response = await api.patch(`/loyalty/${rewardId}/toggle-active`, { set_active: setActive });
    return response.data;
  } catch (error) {
    console.error('Error toggling reward active status:', error);
    throw error;
  }
};
export const toggleHomeItemActive = async (itemId, setActive) => {
  try {
    const response = await api.patch(`/home-items/${itemId}/toggle-active`, { set_active: setActive });
    return response.data;
  } catch (error) {
    console.error('Error toggling home item active status:', error);
    throw error;
  }
};



// Modify this function
export const getImageUrl = (imageFilename) => {
  if (!imageFilename) return null;
  return `${API_URL}/${imageFilename}`;
};

// Update the fetchAuthenticatedImage function
export const fetchAuthenticatedImage = async (imageFilename) => {
  if (!imageFilename) return null;
  try {
    const fullImageUrl = getImageUrl(imageFilename);
    const response = await api.get(fullImageUrl, {
      responseType: 'blob',
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
};


export const toggleFoodicsMenuActive = async (itemId, setActive) => {
  try {
    const response = await api.patch(`/foodics/${itemId}/toggle-active`, { set_active: setActive });
    return response.data;
  } catch (error) {
    console.error('Error toggling Foodics menu active status:', error);
    throw error;
  }
};

export const fetchMenuCategories = async () => {
  try {
    const response = await axios.get(`${public_URL}/public/menu/menu-categories`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Menu Categories:', error);
    throw error;
  }
};

// Update a Foodics menu item
export const updateFoodicsMenuItem = async (itemId, data) => {
  try {
    const response = await api.put(`/foodics/${itemId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating foodics menu item:', error);
    throw error;
  }
};

export const fetchRewardCodes = async (page = 1, pageSize = 10) => {
  try {
    const response = await api.get('/loyalty/reward-codes', {
    params: { page, pageSize },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Reward Codes:', error);
    throw error;
  }
};

export const fetchFoodicsRewardTrans = async (page = 1, pageSize = 10) => {
  try {
    const response = await api.get('/loyalty/foodics-rewardtrans', {
      params: { page, pageSize },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Reward Transactions:', error);
    throw error;
  }
};

export const fetchFoodicsOrders = async (page = 1, pageSize = 10) => {
  try {
    const response = await api.get('/loyalty/foodics-orders', {
      params: { page, pageSize },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Foodics Order Transactions:', error);
    throw error;
  }
};

export const fetchCustomerLoyaltyTrans = async (customerMobileNumber, page = 1, pageSize = 10) => {
  try {
    const response = await api.get(`/customers/reward-earnings/${customerMobileNumber}`, {
      params: { page, pageSize },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching reward earnings:', error);
    throw error;
  }
};

export const fetchCustomerRewardTransactions = async (mobileNumber, page = 1, pageSize = 10) => {
  try {
    const response = await api.get(`/customers/reward-transactions/${mobileNumber}`, {
      params: { page, pageSize },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching reward transactions:', error);
    throw error;
  }
};

export const fetchLoyaltyEarningSets = async () => {
  try {
    const response = await api.get('/loyalty/loyalty-earning-sets');
    return response.data;
  } catch (error) {
    console.error('Error fetching loyalty earning sets:', error);
    throw error;
  }
};

export const updateLoyaltyEarningSet = async (id, updatedData) => {
  try {
    const response = await api.put(`/loyalty/loyalty-earning-sets/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating loyalty earning set:', error);
    throw error;
  }
};


