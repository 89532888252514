import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { fetchEveryCustomerGroups, fetchGroupIdsForReward } from '../services/api';
import AuthenticatedImage from './AuthenticatedImage';

const ViewRewardDetails = ({ open, onClose, reward, onEditClick, foodicsProducts }) => {
  const [groups, setGroups] = useState([]);
  const [groupNames, setGroupNames] = useState([]);

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const data = await fetchEveryCustomerGroups();
        setGroups(data);
      } catch (err) {
        console.error('Failed to load groups:', err);
      }
    };

    loadGroups();
  }, []);

  useEffect(() => {
    const loadGroupNames = async () => {
      if (reward) {
        try {
          const groupIds = await fetchGroupIdsForReward(reward.id);
          const groupNames = groupIds
            .map(groupId => {
              const group = groups.find(g => g.id === groupId.group_id);
              return group ? group.group_name : null;
            })
            .filter(name => name !== null);
          setGroupNames(groupNames);
        } catch (err) {
          console.error('Failed to load group names for reward:', err);
        }
      }
    };

    loadGroupNames();
  }, [reward, groups]);

  const getProductNames = (allowedProductsString) => {
    console.log('Allowed products string:', allowedProductsString);
    console.log('Foodics products:', foodicsProducts);
  
    let allowedProducts;
    try {
      allowedProducts = JSON.parse(allowedProductsString);
    } catch (error) {
      console.error('Error parsing allowed products:', error);
      return 'Error: Invalid product data';
    }
  
    if (!allowedProducts || !allowedProducts.products_list || allowedProducts.products_list.length === 0) {
      console.log('No allowed products found');
      return 'None';
    }
  
    if (!foodicsProducts || foodicsProducts.length === 0) {
      console.log('Foodics products not loaded yet');
      return 'Loading product names...';
    }
  
    const names = allowedProducts.products_list.map((product_id) => {
      console.log('Looking for product with ID:', product_id);
      const product = foodicsProducts.find((p) => p.product_id === product_id);
      console.log('Found product:', product);
      return product ? product.name : product_id;
    }).filter(Boolean); // This will remove any undefined or null values
  
    console.log('Product names:', names);
    
    const productsList = names.length > 0 ? names.join(', ') : 'No matching products found';
    return `Quantity: ${allowedProducts.quantity}, Products: ${productsList}`;
  };

  const getRewardTypeName = (type) => {
    switch (type) {
      case 1:
        return 'Order Discount';
      case 2:
        return 'Product/Combo Discount';
      default:
        return 'Unknown';
    }
  };

  if (!reward) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>View Loyalty Reward</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Reward Title</Typography>
          <Typography>{reward.reward_title}</Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Type</Typography>
          <Typography>{getRewardTypeName(reward.type)}</Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Discount Amount</Typography>
          <Typography>{reward.discount_amount}</Typography>
        </FormControl>
        <FormControl component="fieldset" margin="normal">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={reward.is_percent} disabled />}
              label="Is Percent"
            />
            <FormControlLabel
              control={<Checkbox checked={reward.is_discount_taxable} disabled />}
              label="Is Discount Taxable"
            />
          </FormGroup>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Max Discount Amount</Typography>
          <Typography>{reward.max_discount_amount}</Typography>
        </FormControl>
        <FormControl component="fieldset" margin="normal">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={reward.discount_includes_modifiers} disabled />}
              label="Discount Includes Modifiers"
            />
          </FormGroup>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Allowed Products</Typography>
          <Typography>
            {reward.allowed_products
              ? getProductNames(reward.allowed_products)
              : 'None'}
          </Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Reward Points</Typography>
          <Typography>{reward.reward_points}</Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Reward Description</Typography>
          <Typography>{reward.reward_description}</Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Typography variant="h6">Groups</Typography>
          <Typography>{groupNames.join(', ')}</Typography>
        </FormControl>
        {reward.picture && (
          <FormControl fullWidth margin="normal">
            <Typography variant="h6">Picture</Typography>
            <Box mt={1}>
              <AuthenticatedImage 
                src={reward.picture}
                alt={reward.reward_title}
                style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
              />
            </Box>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        <Button onClick={onEditClick} color="primary" startIcon={<EditIcon />}>
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewRewardDetails;