import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Container,
  Button,
  Box,
} from '@mui/material';
import { fetchEveryCustomerGroups } from '../services/api';
import AddLoyaltyReward from './AddLoyaltyReward';
import ViewLoyaltyRewards from './ViewLoyaltyRewards';

const RewardsManagement = () => {
  const [groupId, setGroupId] = useState('all');
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const data = await fetchEveryCustomerGroups();
        setGroups(data);
      } catch (err) {
        console.error('Failed to load groups:', err);
        setError(err);
      }
    };

    loadGroups();
  }, []);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      // Logic to fetch rewards based on groupId
    } catch (err) {
      console.error('Failed to fetch loyalty rewards:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = (updated) => {
    setIsDialogOpen(false);
    if (updated) {
      setRefreshKey(prevKey => prevKey + 1); // Trigger refresh
    }
  };

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <Card>
        <CardHeader title="View Loyalty Rewards" />
        <CardContent>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="select-group-label">Select Group</InputLabel>
            <Select
              labelId="select-group-label"
              value={groupId}
              onChange={(e) => setGroupId(e.target.value)}
              label="Select Group"
            >
              <MenuItem value="all">All</MenuItem>
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.group_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDialogOpen}>
              Add New Reward
            </Button>
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            <Alert severity="error">{`Error fetching loyalty rewards: ${error.message}`}</Alert>
          ) : (
            <ViewLoyaltyRewards key={refreshKey} groupId={groupId} />
          )}
        </CardContent>
      </Card>
      <AddLoyaltyReward open={isDialogOpen} onClose={handleDialogClose} />
    </Container>
  );
};

export default RewardsManagement;
