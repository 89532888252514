import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography,
  TablePagination,
  CircularProgress
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fetchFoodicsOrders } from '../../services/api';
import ViewOrderDetails from './ViewOrderDetails';

const statusDescriptions = {
  1: 'Pending',
  2: 'Active',
  3: 'Declined',
  4: 'Closed',
  5: 'Returned',
  6: 'Joined',
  7: 'Void',
  8: 'Draft'
};

const ViewFoodicsOrders = () => {
  const [orders, setOrders] = useState([]);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState(null);

  const loadOrders = async (page, rowsPerPage) => {
    try {
      const data = await fetchFoodicsOrders(page + 1, rowsPerPage);
      if (data && data.orders) {
        const sortedOrders = data.orders.sort((a, b) => new Date(b.received_at) - new Date(a.received_at));
        setOrders(sortedOrders);
        setTotalRecords(data.totalRecords);
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error fetching Foodics orders:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loadOrders(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleViewClick = (order) => {
    setCurrentOrder(order);
    setIsViewDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentOrder(null);
  };

  const handleCheckboxChange = (event, orderId) => {
    if (event.target.checked) {
      setSelectedOrders([...selectedOrders, orderId]);
    } else {
      setSelectedOrders(selectedOrders.filter(id => id !== orderId));
    }
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedOrders(orders.map(order => order.order_id));
    } else {
      setSelectedOrders([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {selectedOrders.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <FormControl>
            <Select
              value={action}
              onChange={(event) => setAction(event.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Actions for selected items
              </MenuItem>
              {/* Add any bulk actions here */}
            </Select>
          </FormControl>
        </Box>
      )}
      <Paper sx={{ mt: 4, p: 2 }}>
        <Typography variant="h4" gutterBottom>
          Foodics Orders
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">Error fetching orders: {error}</Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={handleSelectAllChange}
                        checked={selectedOrders.length === orders.length && orders.length > 0}
                        indeterminate={selectedOrders.length > 0 && selectedOrders.length < orders.length}
                      />
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Event Type</TableCell>
                    <TableCell>Customer Mobile Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Received At</TableCell>
                    <TableCell>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedOrders.includes(order.id)}
                          onChange={(event) => handleCheckboxChange(event, order.id)}
                        />
                      </TableCell>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>{order.order_id}</TableCell>
                      <TableCell>{order.event_type}</TableCell>
                      <TableCell>{order.data.customer ? order.data.customer.phone : 'N/A'}</TableCell>
                      <TableCell>{statusDescriptions[order.data.status] || order.data.status}</TableCell>
                      <TableCell>{new Date(order.data.created_at).toLocaleString()}</TableCell>
                      <TableCell>{new Date(order.received_at).toLocaleString()}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleViewClick(order)} sx={{ color: '#1976D2' }}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRecords}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </>
        )}
      </Paper>
      {currentOrder && (
        <ViewOrderDetails
          open={isViewDialogOpen}
          onClose={handleViewDialogClose}
          order={currentOrder}
        />
      )}
    </>
  );
};

export default ViewFoodicsOrders;
