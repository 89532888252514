import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box
} from '@mui/material';
import AuthenticatedImage from './AuthenticatedImage';
import { fetchMenuCategories } from '../services/api';

const FoodicsMenuItemDetails = ({ open, onClose, item }) => {
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchMenuCategories();
        setCategories(data);
        const category = data.find(cat => cat.foodics_id === item.category_id);
        setCategoryName(category ? category.name : 'Unknown');
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    if (item) {
      loadCategories();
    }
  }, [item]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>View Menu Item Details</DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Product ID</TableCell>
              <TableCell>{item.product_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SKU</TableCell>
              <TableCell>{item.sku}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Barcode</TableCell>
              <TableCell>{item.barcode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{item.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name Localized</TableCell>
              <TableCell>{item.name_localized}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>{item.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Is Active</TableCell>
              <TableCell>{item.is_active ? 'Yes' : 'No'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pricing Method</TableCell>
              <TableCell>{item.pricing_method}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Selling Method</TableCell>
              <TableCell>{item.selling_method}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Costing Method</TableCell>
              <TableCell>{item.costing_method}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Preparation Time</TableCell>
              <TableCell>{item.preparation_time}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Price</TableCell>
              <TableCell>{item.price}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cost</TableCell>
              <TableCell>{item.cost}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Calories</TableCell>
              <TableCell>{item.calories}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>{item.product_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product Description</TableCell>
              <TableCell>{item.product_description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product Category</TableCell>
              <TableCell>{categoryName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product Image</TableCell>
              <TableCell>
                {item.product_image ? (
                  <Box mt={2}>
                    <AuthenticatedImage 
                      src={item.product_image}
                      alt="Product"
                      style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }}
                    />
                  </Box>
                ) : 'No Image'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product Price</TableCell>
              <TableCell>{item.product_price}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Set Active</TableCell>
              <TableCell>{item.set_active ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FoodicsMenuItemDetails;
