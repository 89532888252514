import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Button,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import AddMenuItem from './AddMenuItem';
import ViewMenuItems from './ViewMenuItems';
import { fetchMenuItems } from '../services/api';

const MenuManagement = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const loadItems = async () => {
      setIsLoading(true);
      try {
        const data = await fetchMenuItems();
        setItems(data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    loadItems();
  }, []);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = (refresh = false) => {
    setIsDialogOpen(false);
    if (refresh) {
      // Refresh the menu items after adding a new item
      const loadItems = async () => {
        const data = await fetchMenuItems();
        setItems(data);
      };
      loadItems();
    }
  };

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <Card>
        <CardHeader title="View Menu Items" />
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Button variant="contained" color="secondary" onClick={handleDialogOpen}>
              Add New Menu Item
            </Button>
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            <Alert severity="error">{`Error fetching menu items: ${error.message}`}</Alert>
          ) : (
            <ViewMenuItems items={items} setItems={setItems} />
          )}
        </CardContent>
      </Card>
      <AddMenuItem open={isDialogOpen} onClose={handleDialogClose} />
    </Container>
  );
};

export default MenuManagement;
