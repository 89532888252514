import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { fetchAllCustomers, fetchEveryCustomerGroups, addCustomerToGroup } from '../services/api';

const AddCustomerToGroup = () => {
  const [customers, setCustomers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [customerMobileNumber, setCustomerMobileNumber] = useState('');
  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const [groupId, setGroupId] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const loadCustomersAndGroups = async () => {
      try {
        const customersData = await fetchAllCustomers();
        setCustomers(customersData);
        const groupsData = await fetchEveryCustomerGroups();
        setGroups(groupsData);
      } catch (error) {
        console.error('Error loading customers and groups:', error);
      }
    };

    loadCustomersAndGroups();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { customer_mobile_number: customerMobileNumber, mobile_country_code: parseInt(mobileCountryCode), group_id: parseInt(groupId) };

    try {
      const response = await addCustomerToGroup(data);
      setMessage(response.message);
    } catch (error) {
      console.error('Error adding customer to group:', error);
      setMessage('Failed to add customer to group');
    }
  };

  return (
    <Card className="mt-4">
      <Card.Header as="h2">Add Customer to Group</Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formCustomerMobileNumber">
            <Form.Label>Customer Mobile Number</Form.Label>
            <Form.Control
              as="select"
              value={customerMobileNumber}
              onChange={(e) => setCustomerMobileNumber(e.target.value)}
            >
              <option value="">Select customer mobile number</option>
              {customers.map((customer) => (
                <option key={customer.customer_mobile_number} value={customer.customer_mobile_number}>
                  {customer.customer_mobile_number} ({customer.customer_name})
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formMobileCountryCode">
            <Form.Label>Mobile Country Code</Form.Label>
            <Form.Control
              type="text"
              value={mobileCountryCode}
              onChange={(e) => setMobileCountryCode(e.target.value)}
              placeholder="Enter mobile country code"
            />
          </Form.Group>

          <Form.Group controlId="formGroupId">
            <Form.Label>Group</Form.Label>
            <Form.Control
              as="select"
              value={groupId}
              onChange={(e) => setGroupId(e.target.value)}
            >
              <option value="">Select group</option>
              {groups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.group_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit">
            Add to Group
          </Button>
        </Form>
        {message && <p className="mt-3">{message}</p>}
      </Card.Body>
    </Card>
  );
};

export default AddCustomerToGroup;
