import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { fetchLoyaltyEarningSets, updateLoyaltyEarningSet } from '../services/api';

const ViewEarningSettings = () => {
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null);
  const [updatedSetting, setUpdatedSetting] = useState({});
  const [updateError, setUpdateError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);

  const loadSettings = async () => {
    setIsLoading(true);
    try {
      const data = await fetchLoyaltyEarningSets();
      console.log('Fetched data:', data); // Debugging information
      setSettings(data.settings);
    } catch (err) {
      console.error('Error fetching settings:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const handleEditClick = (setting) => {
    setCurrentSetting(setting);
    setUpdatedSetting(setting);
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    setCurrentSetting(null);
    setUpdatedSetting({});
    setUpdateError(null);
    setUpdateSuccess(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdatedSetting({
      ...updatedSetting,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    try {
      await updateLoyaltyEarningSet(currentSetting.id, updatedSetting);
      setUpdateSuccess('Settings updated successfully');
      loadSettings();
      handleEditDialogClose(); // Close the dialog after successful update
    } catch (err) {
      console.error('Error updating settings:', err);
      setUpdateError(err.message);
    }
  };

  return (
    <Paper sx={{ mt: 4, p: 2 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Loyalty Earning Settings
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error.message}
        </Alert>
      )}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Minimum Order Price</TableCell>
                  <TableCell>Earning Delay</TableCell>
                  <TableCell>Reward Points</TableCell>
                  <TableCell>Groups</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settings && settings.length > 0 ? (
                  settings.map((setting) => (
                    <TableRow key={setting.id}>
                      <TableCell>{setting.id}</TableCell>
                      <TableCell>{setting.minimum_order_price}</TableCell>
                      <TableCell>{setting.earning_delay}</TableCell>
                      <TableCell>{setting.reward_points}</TableCell>
                      <TableCell>{JSON.stringify(setting.groups)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditClick(setting)} sx={{ color: '#1976D2' }}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No settings found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Setting</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Minimum Order Price"
            type="number"
            name="minimum_order_price"
            fullWidth
            value={updatedSetting.minimum_order_price || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Earning Delay"
            type="number"
            name="earning_delay"
            fullWidth
            value={updatedSetting.earning_delay || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Reward Points"
            type="number"
            name="reward_points"
            fullWidth
            value={updatedSetting.reward_points || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Groups"
            type="text"
            name="groups"
            fullWidth
            value={JSON.stringify(updatedSetting.groups) || ''}
            onChange={handleChange}
          />
          {updateError && <Alert severity="error">{updateError}</Alert>}
          {updateSuccess && <Alert severity="success">{updateSuccess}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ViewEarningSettings;
