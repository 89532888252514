import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { addCustomer } from '../services/api';

const AddCustomer = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerMobileNumber, setCustomerMobileNumber] = useState('');
  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newCustomer = {
      customer_name: customerName,
      customer_mobile_number: customerMobileNumber,
      mobile_country_code: parseInt(mobileCountryCode),
    };

    try {
      const response = await addCustomer(newCustomer);
      setMessage(response.message);
    } catch (error) {
      console.error('Error adding customer:', error);
      setMessage('Failed to add customer');
    }
  };

  return (
    <Card className="mt-4">
      <Card.Header as="h2">Add New Customer</Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formCustomerName">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              placeholder="Enter customer name"
            />
          </Form.Group>

          <Form.Group controlId="formCustomerMobileNumber">
            <Form.Label>Customer Mobile Number</Form.Label>
            <Form.Control
              type="text"
              value={customerMobileNumber}
              onChange={(e) => setCustomerMobileNumber(e.target.value)}
              placeholder="Enter customer mobile number"
            />
          </Form.Group>

          <Form.Group controlId="formMobileCountryCode">
            <Form.Label>Mobile Country Code</Form.Label>
            <Form.Control
              type="text"
              value={mobileCountryCode}
              onChange={(e) => setMobileCountryCode(e.target.value)}
              placeholder="Enter mobile country code"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Add Customer
          </Button>
        </Form>
        {message && <p className="mt-3">{message}</p>}
      </Card.Body>
    </Card>
  );
};

export default AddCustomer;
