import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { updateBusinessRef } from '../services/api';

const EditBusinessRef = ({ open, onClose, refData }) => {
  const [businessReference, setBusinessReference] = useState(refData.business_reference);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateBusinessRef(refData.id, { business_reference: businessReference });
      onClose(true);
    } catch (error) {
      console.error('Error updating business reference:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Edit Business Reference</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Business Reference"
          fullWidth
          value={businessReference}
          onChange={(e) => setBusinessReference(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="secondary"
          disabled={isSaving}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBusinessRef;
