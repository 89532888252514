import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ViewCustomers from './components/ViewCustomers';
import AddCustomer from './components/AddCustomer';
import ViewCustomerGroups from './components/ViewCustomerGroups';
import AddCustomerGroup from './components/AddCustomerGroup';
import AddCustomerToGroup from './components/AddCustomerToGroup';
import AddLoyaltyReward from './components/AddLoyaltyReward';
import ViewLoyaltyRewards from './components/ViewLoyaltyRewards';
import LoyaltyCustomers from './components/LoyaltyCustomers';
import RedeemReward from './components/RedeemReward';
import Login from './components/Login';
import Register from './components/Register';
import CheckSpecificReward from './components/CheckSpecificReward';
import FoodicsProducts from './components/FoodicsProducts';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import RewardsManagement from './components/RewardsManagement';
import AppHomeScreen from './components/AppHomeScreen';
import MenuManagement from './components/MenuManagement'; // Import the MenuManagement component
import FoodicsMenuItems from './components/FoodicsMenuItems';
import RewardTransactions from './components/RewardTransactions/ViewRewardTransactions';
import FoodicsRewardTrans from './components/FoodicsRewardTrans/ViewRewardTransactions';
import ViewFoodicsOrders from './components/FoodicsOrders/ViewFoodicsOrders';
import ViewCustomerLoyaltyTrans from './components/ViewCustomerLoyaltyTrans';
import ViewEarningSettings from './components/ViewEarningSettings';
import ViewBusinessRefs from './components/ViewBusinessRefs';

const AppRoutes = ({ token, handleLogin }) => (
  <Routes>
    <Route path="/login" element={<Login onLogin={handleLogin} />} />
    <Route path="/register" element={<Register />} />
    <Route
      path="/dashboard"
      element={
        <PrivateRoute token={token}>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/customers"
      element={
        <PrivateRoute token={token}>
          <ViewCustomers />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-groups"
      element={
        <PrivateRoute token={token}>
          <ViewCustomerGroups />
        </PrivateRoute>
      }
    />
    <Route
      path="/customer-loyalty-trans/:customerMobileNumber"
      element={
        <PrivateRoute token={token}>
          <ViewCustomerLoyaltyTrans />
        </PrivateRoute>
      }
    />
    <Route
      path="/add-loyalty-reward"
      element={
        <PrivateRoute token={token}>
          <AddLoyaltyReward />
        </PrivateRoute>
      }
    />
    <Route
      path="/view-loyalty-rewards"
      element={
        <PrivateRoute token={token}>
          <ViewLoyaltyRewards />
        </PrivateRoute>
      }
    />
    <Route
      path="/loyalty-customers"
      element={
        <PrivateRoute token={token}>
          <LoyaltyCustomers />
        </PrivateRoute>
      }
    />
    <Route
      path="/redeem-reward"
      element={
        <PrivateRoute token={token}>
          <RedeemReward />
        </PrivateRoute>
      }
    />
    <Route
      path="/check-specific-reward"
      element={
        <PrivateRoute token={token}>
          <CheckSpecificReward />
        </PrivateRoute>
      }
    />
    <Route
      path="/rewards-management"
      element={
        <PrivateRoute token={token}>
          <RewardsManagement />
        </PrivateRoute>
      }
    />
    <Route
      path="/rewards-transactions"
      element={
        <PrivateRoute token={token}>
          <RewardTransactions />
        </PrivateRoute>
      }
    />
    <Route
      path="/earning-settings"
      element={
        <PrivateRoute token={token}>
          <ViewEarningSettings />
        </PrivateRoute>
      }
    />
    <Route
      path="/business-reference"
      element={
        <PrivateRoute token={token}>
          <ViewBusinessRefs />
        </PrivateRoute>
      }
    />
    <Route
      path="/foodics-products"
      element={
        <PrivateRoute token={token}>
          <FoodicsProducts />
        </PrivateRoute>
      }
    />
    <Route
      path="/foodics-menuitems"
      element={
        <PrivateRoute token={token}>
          <FoodicsMenuItems />
        </PrivateRoute>
      }
    />
    <Route
      path="/foodicsreward-trans"
      element={
        <PrivateRoute token={token}>
          <FoodicsRewardTrans />
        </PrivateRoute>
      }
    />
    <Route
      path="/foodics-orders"
      element={
        <PrivateRoute token={token}>
          <ViewFoodicsOrders />
        </PrivateRoute>
      }
    />
    <Route
      path="/app-home"
      element={
        <PrivateRoute token={token}>
          <AppHomeScreen />
        </PrivateRoute>
      }
    />
    <Route
      path="/menu-management"
      element={
        <PrivateRoute token={token}>
          <MenuManagement />
        </PrivateRoute>
      }
    />
    <Route path="*" element={<Navigate to={token ? "/dashboard" : "/login"} />} />
  </Routes>
);

export default AppRoutes;
