import React, { useState } from 'react';
import { Button, Card, Form, ListGroup } from 'react-bootstrap';
import { fetchCustomerLoyaltyRewards } from '../services/api';

const LoyaltyCustomers = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerMobileNumber, setCustomerMobileNumber] = useState('');
  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const customerData = {
      customer_mobile_number: customerMobileNumber,
      mobile_country_code: parseInt(mobileCountryCode),
    };

    setIsLoading(true);
    setError(null); // Reset error state before making the request
    try {
      const data = await fetchCustomerLoyaltyRewards(customerData);
      setRewards(data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="mt-4">
      <Card.Header as="h2">Customer Loyalty Rewards</Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formCustomerName">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              placeholder="Enter customer name"
            />
          </Form.Group>

          <Form.Group controlId="formCustomerMobileNumber">
            <Form.Label>Customer Mobile Number</Form.Label>
            <Form.Control
              type="text"
              value={customerMobileNumber}
              onChange={(e) => setCustomerMobileNumber(e.target.value)}
              placeholder="Enter customer mobile number"
            />
          </Form.Group>

          <Form.Group controlId="formMobileCountryCode">
            <Form.Label>Mobile Country Code</Form.Label>
            <Form.Control
              type="text"
              value={mobileCountryCode}
              onChange={(e) => setMobileCountryCode(e.target.value)}
              placeholder="Enter mobile country code"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Fetch Rewards
          </Button>
        </Form>

        {isLoading ? (
          <p>Loading rewards...</p>
        ) : error ? (
          <p>Error fetching rewards: {error.message}</p>
        ) : (
          <ListGroup variant="flush" className="mt-4">
            {rewards.map((reward, index) => (
              <ListGroup.Item key={index}>
                <h5>Type: {reward.type}</h5>
                <p>Discount Amount: {reward.discount_amount}</p>
                <p>Is Percent: {reward.is_percent ? 'Yes' : 'No'}</p>
                <p>Customer Mobile Number: {reward.customer_mobile_number}</p>
                <p>Mobile Country Code: {reward.mobile_country_code}</p>
                <p>Reward Code: {reward.reward_code}</p>
                <p>Business Reference: {reward.business_reference}</p>
                <p>Max Discount Amount: {reward.max_discount_amount}</p>
                <p>Discount Includes Modifiers: {reward.discount_includes_modifiers ? 'Yes' : 'No'}</p>
                <p>Allowed Products: {reward.allowed_products ? reward.allowed_products.products_list.join(', ') : 'None'}</p>
                <p>Is Discount Taxable: {reward.is_discount_taxable ? 'Yes' : 'No'}</p>
                <p>Redeemed: {reward.is_redeemed ? 'Yes' : 'No'}</p>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Card.Body>
    </Card>
  );
};

export default LoyaltyCustomers;
