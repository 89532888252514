// Navigation.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from '@mui/icons-material/Group';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';

// Include the Raleway font
import '@fontsource/raleway';

const drawerWidth = 320;

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: '#f69a33',
    color: 'white',
    borderRight: 'none',
    fontFamily: 'Raleway, sans-serif', // Use Raleway font
  },
}));

const LogoBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
}));

const Logo = styled(Box)(({ theme }) => ({
  height: 80,
  margin: 'auto',
  display: 'block',
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  color: 'white',
  borderRadius: theme.spacing(1),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '& .MuiTypography-root': {
    fontSize: '1rem',
    fontFamily: 'Raleway, sans-serif', // Use Raleway font
  },
  '&.Mui-selected': {
    backgroundColor: '#cc7000',
    '&:hover': {
      backgroundColor: '#cc7000', // Keeps the same color when selected
    },
  },
  '&:hover': {
    backgroundColor: '#cc7000', // Changes color on hover when not selected
  },
}));

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'inherit',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#f69a33',
  color: 'white',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '&.Mui-expanded': {
    minHeight: 'auto',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    alignItems: 'center',
  },
  '& .MuiTypography-root': {
    fontSize: '1rem',
    fontFamily: 'Raleway, sans-serif', // Use Raleway font
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: '#e68a2d',
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  backgroundColor: '#cc7000',
  color: 'white',
  '&:hover': {
    backgroundColor: '#e68a2d',
  },
  fontSize: '1rem',
  fontFamily: 'Raleway, sans-serif', // Use Raleway font
}));

const Navigation = ({ handleLogout }) => {
  const location = useLocation();

  const isSelected = (path) => location.pathname === path;

  return (
    <DrawerStyled variant="permanent">
      <LogoBox>
        <Logo component="img" src="/logo.jpg" alt="Blumen Cafe Logo" />
      </LogoBox>
      <List disablePadding>
        <ListItemStyled button component={Link} to="/dashboard" selected={isSelected('/dashboard')}>
          <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemStyled>
        <AccordionStyled disableGutters elevation={0} square>
          <AccordionSummaryStyled expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
              <PeopleIcon />
            </ListItemIcon>
            <Typography>Customers</Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/customers" selected={isSelected('/customers')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="View Customers" />
              </ListItemStyled>
              <ListItemStyled button component={Link} to="/customer-groups" selected={isSelected('/customer-groups')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="View Customer Groups" />
              </ListItemStyled>
            </List>
          </AccordionDetailsStyled>
        </AccordionStyled>
        <AccordionStyled disableGutters elevation={0} square>
          <AccordionSummaryStyled expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
              <CardGiftcardIcon />
            </ListItemIcon>
            <Typography>Rewards Management</Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/rewards-management" selected={isSelected('/rewards-management')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <CardGiftcardIcon />
                </ListItemIcon>
                <ListItemText primary="Rewards" />
              </ListItemStyled>
            </List>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/rewards-transactions" selected={isSelected('/rewards-transactions')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <CardGiftcardIcon />
                </ListItemIcon>
                <ListItemText primary="Reward Transactions" />
              </ListItemStyled>
            </List>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/earning-settings" selected={isSelected('/earning-settings')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <CardGiftcardIcon />
                </ListItemIcon>
                <ListItemText primary="Loyalty Earning Settings" />
              </ListItemStyled>
            </List>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/business-reference" selected={isSelected('/business-reference')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <CardGiftcardIcon />
                </ListItemIcon>
                <ListItemText primary="Business Reference" />
              </ListItemStyled>
            </List>
          </AccordionDetailsStyled>
        </AccordionStyled>
        <AccordionStyled disableGutters elevation={0} square>
          <AccordionSummaryStyled expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
              <HomeIcon />
            </ListItemIcon>
            <Typography>App Management</Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/app-home" selected={isSelected('/app-home')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="App Homescreen" />
              </ListItemStyled>
              <ListItemStyled button component={Link} to="/menu-management" selected={isSelected('/menu-management')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <RestaurantMenuIcon />
                </ListItemIcon>
                <ListItemText primary="Menu Management" />
              </ListItemStyled>
            </List>
          </AccordionDetailsStyled>
        </AccordionStyled>
        <AccordionStyled disableGutters elevation={0} square>
          <AccordionSummaryStyled expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
              <RestaurantMenuIcon />
            </ListItemIcon>
            <Typography>Foodics Management</Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/foodics-menuitems" selected={isSelected('/foodics-menuitems')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <RestaurantMenuIcon />
                </ListItemIcon>
                <ListItemText primary="Foodics Menu Items" />
              </ListItemStyled>
            </List>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/foodicsreward-trans" selected={isSelected('/foodicsreward-trans')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <RestaurantMenuIcon />
                </ListItemIcon>
                <ListItemText primary="Foodics Reward Transactions" />
              </ListItemStyled>
            </List>
            <List disablePadding>
              <ListItemStyled button component={Link} to="/foodics-orders" selected={isSelected('/foodics-orders')}>
                <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                  <RestaurantMenuIcon />
                </ListItemIcon>
                <ListItemText primary="Foodics Orders/Webhooks" />
              </ListItemStyled>
            </List>
          </AccordionDetailsStyled>
        </AccordionStyled>
        <ListItem>
          <LogoutButton variant="contained" onClick={handleLogout} fullWidth startIcon={<LogoutIcon />}>
            Logout
          </LogoutButton>
        </ListItem>
      </List>
    </DrawerStyled>
  );
};

export default Navigation;
