import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
} from '@mui/material';

const ViewRewardDetails = ({ open, onClose, reward }) => {
  if (!reward) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Reward Details</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h6">Reward Code: {reward.reward_code}</Typography>
          <Typography variant="body1">
            <strong>Customer Mobile Number:</strong> {reward.customer_mobile_number}
          </Typography>
          <Typography variant="body1">
            <strong>Mobile Country Code:</strong> {reward.mobile_country_code}
          </Typography>
          <Typography variant="body1">
            <strong>Branch ID:</strong> {reward.branch_id}
          </Typography>
          <Typography variant="body1">
            <strong>Business Reference:</strong> {reward.business_reference}
          </Typography>
          <Typography variant="body1">
            <strong>Discount Amount:</strong> {reward.discount_amount}
          </Typography>
          <Typography variant="body1">
            <strong>Date:</strong> {new Date(reward.date).toLocaleString()}
          </Typography>
          <Typography variant="body1">
            <strong>User ID:</strong> {reward.user_id}
          </Typography>
          <Typography variant="body1">
            <strong>Order ID:</strong> {reward.order_id}
          </Typography>
          <Typography variant="body1">
            <strong>Redeemed Combos:</strong> {JSON.stringify(reward.redeemed_combos, null, 2)}
          </Typography>
          <Typography variant="body1">
            <strong>Redeemed Products:</strong> {JSON.stringify(reward.redeemed_products, null, 2)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewRewardDetails;
