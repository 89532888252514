import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box
} from '@mui/material';

const ViewRewardDetails = ({ open, onClose, reward }) => {
  if (!reward) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Reward Details</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="subtitle1">Reward Code:</Typography>
          <Typography variant="body1">{reward.reward_code}</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">Customer Mobile Number:</Typography>
          <Typography variant="body1">{reward.customer_mobile_number}</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">Reward Points:</Typography>
          <Typography variant="body1">{reward.reward_points}</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">Redeem Status:</Typography>
          <Typography variant="body1">{reward.redeem_status ? 'Redeemed' : 'Not Redeemed'}</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">Used Status:</Typography>
          <Typography variant="body1">{reward.used_status ? 'Used' : 'Not Used'}</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">Created At:</Typography>
          <Typography variant="body1">{new Date(reward.createdAt).toLocaleString()}</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">Updated At:</Typography>
          <Typography variant="body1">{new Date(reward.updatedAt).toLocaleString()}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewRewardDetails;
