import React, { useState } from 'react';
import axios from 'axios';

const CheckSpecificReward = () => {
  const [customerMobileNumber, setCustomerMobileNumber] = useState('');
  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const [rewardCode, setRewardCode] = useState('');
  const [businessReference, setBusinessReference] = useState('');
  const [reward, setReward] = useState([]);
  const [error, setError] = useState(null);

  const handleCheckReward = async () => {
    try {
      const response = await axios.post(
        'http://localhost:5004/api/loyalty/specific-reward',
        {
          customer_mobile_number: customerMobileNumber,
          mobile_country_code: mobileCountryCode,
          reward_code: rewardCode,
          business_reference: businessReference
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setReward(response.data);
      setError(null);
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);
      setReward([]);
    }
  };

  const renderAllowedProducts = (allowedProducts) => {
    if (!allowedProducts) return 'None';

    const { quantity, products_list } = allowedProducts;
    return (
      <div>
        <p>Quantity: {quantity}</p>
        <p>Products List: {products_list.join(', ')}</p>
      </div>
    );
  };

  return (
    <div className="container mt-4">
      <h2>Check Specific Reward</h2>
      <div className="mb-3">
        <label className="form-label">Customer Mobile Number</label>
        <input
          type="text"
          className="form-control"
          value={customerMobileNumber}
          onChange={(e) => setCustomerMobileNumber(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Mobile Country Code</label>
        <input
          type="text"
          className="form-control"
          value={mobileCountryCode}
          onChange={(e) => setMobileCountryCode(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Reward Code</label>
        <input
          type="text"
          className="form-control"
          value={rewardCode}
          onChange={(e) => setRewardCode(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Business Reference</label>
        <input
          type="text"
          className="form-control"
          value={businessReference}
          onChange={(e) => setBusinessReference(e.target.value)}
        />
      </div>
      <button className="btn btn-primary" onClick={handleCheckReward}>
        Check Reward
      </button>

      {error && <div className="alert alert-danger mt-3">{error}</div>}

      {reward && reward.length > 0 && (
        <div className="mt-3">
          <h4>Reward Details</h4>
          {reward.map((r, index) => (
            <div key={index} className="reward-details">
              <p><strong>Type:</strong> {r.type}</p>
              <p><strong>Discount Amount:</strong> {r.discount_amount}</p>
              <p><strong>Is Percent:</strong> {r.is_percent ? 'Yes' : 'No'}</p>
              <p><strong>Customer Mobile Number:</strong> {r.customer_mobile_number}</p>
              <p><strong>Mobile Country Code:</strong> {r.mobile_country_code}</p>
              <p><strong>Reward Code:</strong> {r.reward_code}</p>
              <p><strong>Business Reference:</strong> {r.business_reference}</p>
              <p><strong>Max Discount Amount:</strong> {r.max_discount_amount}</p>
              <p><strong>Discount Includes Modifiers:</strong> {r.discount_includes_modifiers ? 'Yes' : 'No'}</p>
              <div><strong>Allowed Products:</strong> {renderAllowedProducts(r.allowed_products)}</div>
              <p><strong>Is Discount Taxable:</strong> {r.is_discount_taxable ? 'Yes' : 'No'}</p>
              <p><strong>Reward Points:</strong> {r.reward_points}</p>
              <p><strong>Reward Title:</strong> {r.reward_title}</p>
              <p><strong>Reward Description:</strong> {r.reward_description}</p>
              {r.picture && <div><strong>Picture:</strong> <img src={r.picture} alt={r.reward_title} /></div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckSpecificReward;
