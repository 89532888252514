import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControl,
} from '@mui/material';
import { addMenuItem } from '../services/api';

const AddMenuItem = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState('');
  const [category, setCategory] = useState('');
  const [foodicsId, setFoodicsId] = useState('');
  const [message, setMessage] = useState('');

  const handleSave = async () => {
    try {
      const newItem = { name, description, price, image, category, foodics_id: foodicsId };
      await addMenuItem(newItem);
      setMessage('Menu item added successfully');
      onClose(true);
    } catch (error) {
      setMessage('Error adding menu item');
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Add Menu Item</DialogTitle>
      <DialogContent>
        <DialogContentText>Fill out the form below to add a new menu item.</DialogContentText>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter menu item name"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter menu item description"
            multiline
            rows={3}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Enter menu item price"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Image URL"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            placeholder="Enter menu item image URL"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Enter menu item category"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Foodics ID"
            value={foodicsId}
            onChange={(e) => setFoodicsId(e.target.value)}
            placeholder="Enter Foodics ID"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="secondary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Add Item</Button>
      </DialogActions>
      {message && <DialogContentText>{message}</DialogContentText>}
    </Dialog>
  );
};

export default AddMenuItem;
