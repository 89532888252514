import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
} from '@mui/material';

const statusDescriptions = {
  1: 'Pending',
  2: 'Active',
  3: 'Declined',
  4: 'Closed',
  5: 'Returned',
  6: 'Joined',
  7: 'Void',
  8: 'Draft'
};

const ViewOrderDetails = ({ open, onClose, order }) => {
  if (!order) {
    return null;
  }

  const renderDetail = (label, value) => (
    <Typography variant="body1">
      <strong>{label}:</strong> {value}
    </Typography>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Order Details</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {renderDetail('Order ID', order.order_id)}
          {renderDetail('Event Type', order.event_type)}
          {order.data && (
            <>
              {renderDetail('Branch ID', order.data.branch?.id)}
              {renderDetail('Branch Name', order.data.branch?.name)}
              {renderDetail('Customer ID', order.data.customer?.id)}
              {renderDetail('Customer Name', order.data.customer?.name)}
              {renderDetail('Customer Mobile Number', order.data.customer?.phone)}
              {renderDetail('Status', statusDescriptions[order.data.status] || order.data.status)}
              {renderDetail('Total Price', order.data.total_price)}
              {renderDetail('Created At', new Date(order.data.created_at).toLocaleString())}
              {renderDetail('Updated At', new Date(order.data.updated_at).toLocaleString())}
            </>
          )}
          {renderDetail('Received At', new Date(order.received_at).toLocaleString())}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewOrderDetails;
