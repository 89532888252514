import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { fetchBusinessRefs, deleteBusinessRef } from '../services/api';
import ViewBusinessRefDetails from './ViewBusinessRefDetails';
import EditBusinessRef from './EditBusinessRef';

const ViewBusinessRefs = () => {
  const [businessRefs, setBusinessRefs] = useState([]);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentRef, setCurrentRef] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [refToDelete, setRefToDelete] = useState(null);

  useEffect(() => {
    const loadBusinessRefs = async () => {
      const data = await fetchBusinessRefs();
      setBusinessRefs(data);
    };
    loadBusinessRefs();
  }, []);

  const handleViewClick = (ref) => {
    setCurrentRef(ref);
    setIsViewDialogOpen(true);
  };

  const handleEditClick = (ref) => {
    setCurrentRef(ref);
    setIsEditDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentRef(null);
  };

  const handleEditDialogClose = (updated) => {
    if (updated) {
      const loadBusinessRefs = async () => {
        const data = await fetchBusinessRefs();
        setBusinessRefs(data);
      };
      loadBusinessRefs();
    }
    setIsEditDialogOpen(false);
    setCurrentRef(null);
  };

  const handleDeleteClick = (refId) => {
    setRefToDelete(refId);
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteBusinessRef(refToDelete);
      const loadBusinessRefs = async () => {
        const data = await fetchBusinessRefs();
        setBusinessRefs(data);
      };
      loadBusinessRefs();
    } catch (error) {
      console.error('Error deleting business reference:', error);
    } finally {
      setIsConfirmDialogOpen(false);
      setRefToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmDialogOpen(false);
    setRefToDelete(null);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Business Reference</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {businessRefs.map((ref) => (
              <TableRow key={ref.id}>
                <TableCell>{ref.id}</TableCell>
                <TableCell>{ref.business_reference}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleViewClick(ref)} sx={{ color: '#1976D2' }}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(ref)} sx={{ color: '#1976D2' }}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteClick(ref.id)} sx={{ color: 'red' }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {currentRef && (
        <ViewBusinessRefDetails
          open={isViewDialogOpen}
          onClose={handleViewDialogClose}
          refData={currentRef}
          onEditClick={() => handleEditClick(currentRef)}
        />
      )}

      {currentRef && (
        <EditBusinessRef
          open={isEditDialogOpen}
          onClose={handleEditDialogClose}
          refData={currentRef}
        />
      )}

      <Dialog open={isConfirmDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this business reference? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewBusinessRefs;
