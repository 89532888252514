import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL, Direct_API_URL, public_URL } from '../config';

const API_URL_Auth = `${Direct_API_URL}/auth`;

export const register = async (username, password) => {
  const response = await axios.post(`${API_URL_Auth}/register`, { username, password });
  return response.data;
};

export const login = async (username, password) => {
  const response = await axios.post(`${API_URL_Auth}/login`, { username, password });
  Cookies.set('token', response.data.token, {
    expires: 0.25, // Set token to expire in 15 minutes
    secure: true, // Cookie will only be sent over HTTPS
    sameSite: 'Strict', // CSRF protection
  });
  Cookies.set('refreshToken', response.data.refreshToken, {
    expires: 7, // Set refresh token to expire in 7 days
    secure: true, // Cookie will only be sent over HTTPS
    sameSite: 'Strict', // CSRF protection
  });
  return response.data;
};

export const logout = async () => {
  const refreshToken = Cookies.get('refreshToken');
  await axios.post(`${API_URL_Auth}/logout`, { token: refreshToken });
  Cookies.remove('token', { secure: true, sameSite: 'Strict' });
  Cookies.remove('refreshToken', { secure: true, sameSite: 'Strict' });
};

export const refreshToken = async () => {
  const refreshToken = Cookies.get('refreshToken');
  const response = await axios.post(`${API_URL_Auth}/token`, { token: refreshToken });
  Cookies.set('token', response.data.accessToken, {
    expires: 0.25, // Reset token to expire in 15 minutes
    secure: true, // Cookie will only be sent over HTTPS
    sameSite: 'Strict', // CSRF protection
  });
  Cookies.set('refreshToken', response.data.refreshToken, {
    expires: 7, // Reset refresh token to expire in 7 days
    secure: true, // Cookie will only be sent over HTTPS
    sameSite: 'Strict', // CSRF protection
  });
  return response.data;
};
