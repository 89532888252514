import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const ViewBusinessRefDetails = ({ open, onClose, refData, onEditClick }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Business Reference Details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Below are the details for the selected business reference.
        </DialogContentText>
        <Typography variant="body1" gutterBottom>
          <strong>ID:</strong> {refData.id}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Business Reference:</strong> {refData.business_reference}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={onEditClick} color="secondary">
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewBusinessRefDetails;
