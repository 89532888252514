import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/auth';
import { TextField, Button, Card, CardContent, CardHeader, Typography } from '@mui/material';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await login(username, password);
      onLogin(data.token);
      navigate('/dashboard'); // Redirect to Dashboard
    } catch (error) {
      setMessage('Invalid credentials');
    }
  };

  return (
    <Card sx={{ mt: 4, maxWidth: 400, mx: 'auto' }}>
      <CardHeader title="Login" />
      <CardContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
          />
          <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
            Login
          </Button>
        </form>
        {message && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default Login;
