import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import { updateHomeItem } from '../services/api';
import ImageUpload from './ImageUpload';

const EditHomeItem = ({ open, onClose, item }) => {
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    content: '',
    image: '',
  });
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (item) {
      setFormData({
        title: item.title || '',
        subtitle: item.subtitle || '',
        content: item.content || '',
        image: item.image || '',
      });
    }
  }, [item]);

  const validateFields = () => {
    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (value.trim() === '' && key !== 'image') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      }
    });
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      if (errors.image) {
        setErrors((prev) => ({ ...prev, image: '' }));
      }
    }
  };

  const resetForm = () => {
    setFormData({
      title: item?.title || '',
      subtitle: item?.subtitle || '',
      content: item?.content || '',
      image: item?.image || '',
    });
    setImage(null);
    setErrors({});
    setMessage('');
  };

  const handleClose = (saved = false) => {
    resetForm();
    onClose(saved);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    if (image) {
      formDataToSend.append('image', image);
    }

    try {
      const response = await updateHomeItem(item.id, formDataToSend);
      setMessage(response.message || 'Successfully updated home item');
      handleClose(true);
    } catch (error) {
      console.error('Error updating home item:', error);
      setMessage('Failed to update home item');
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Home Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill out the form below to edit the home item.
        </DialogContentText>
        {Object.entries(formData).map(([key, value]) => (
          key !== 'image' && (
            <FormControl key={key} fullWidth margin="normal">
              <TextField
                label={key.charAt(0).toUpperCase() + key.slice(1)}
                name={key}
                value={value}
                onChange={handleInputChange}
                placeholder={`Enter item ${key}`}
                multiline={key === 'content'}
                rows={key === 'content' ? 3 : 1}
                error={!!errors[key]}
                helperText={errors[key]}
              />
            </FormControl>
          )
        ))}
        <ImageUpload
          onChange={handleImageChange}
          error={errors.image}
          value={image}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save Home Item
        </Button>
      </DialogActions>
      {message && <DialogContentText style={{ padding: '0 24px 20px' }}>{message}</DialogContentText>}
    </Dialog>
  );
};

export default EditHomeItem;