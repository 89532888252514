import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Box,
  Alert,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { fetchHomeItems, deleteHomeItem, toggleHomeItemActive, fetchAuthenticatedImage } from '../services/api';
import AddHomeItem from './AddHomeItem';
import EditHomeItem from './EditHomeItem';
import AuthenticatedImage from './AuthenticatedImage';

const ViewHomeItems = () => {
  const [items, setItems] = useState([]);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [action, setAction] = useState('');
  const [isConfirmActivateDialogOpen, setIsConfirmActivateDialogOpen] = useState(false);
  const [isConfirmDeactivateDialogOpen, setIsConfirmDeactivateDialogOpen] = useState(false);

  useEffect(() => {
    const loadHomeItems = async () => {
      setIsLoading(true);
      try {
        const data = await fetchHomeItems();
        const sortedData = data.sort((a, b) => b.id - a.id);
        setItems(sortedData);
      } catch (err) {
        console.error('Error fetching home items:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadHomeItems();
  }, []);

  const handleViewClick = (item) => {
    setCurrentItem(item);
    setIsViewDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentItem(null);
  };

  const handleDeleteClick = (itemId) => {
    setItemToDelete(itemId);
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (Array.isArray(itemToDelete)) {
        await Promise.all(itemToDelete.map(id => deleteHomeItem(id)));
        setItems((prevItems) => prevItems.filter((item) => !itemToDelete.includes(item.id)));
      } else {
        await deleteHomeItem(itemToDelete);
        setItems((prevItems) => prevItems.filter((item) => item.id !== itemToDelete));
      }
      setAction(''); // Reset action after successful delete
    } catch (err) {
      setError(err);
    } finally {
      setIsConfirmDialogOpen(false);
      setItemToDelete(null);
      setSelectedItems([]); // Clear selected items
      setAction(''); // Reset action even if there's an error
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmDialogOpen(false);
    setItemToDelete(null);
    setAction(''); // Reset action when cancel is pressed
    setSelectedItems([]); // Clear selected items on cancel
  };

  const handleAddClick = () => {
    setIsAddDialogOpen(true);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogOpen(false);
  };

  const handleEditClick = (item) => {
    setIsViewDialogOpen(false);
    setCurrentItem(item);
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = (updated) => {
    if (updated) {
      const loadHomeItems = async () => {
        setIsLoading(true);
        try {
          const data = await fetchHomeItems();
          setItems(data);
        } catch (err) {
          setError(err);
        } finally {
          setIsLoading(false);
        }
      };

      loadHomeItems();
    }
    setIsEditDialogOpen(false);
    setCurrentItem(null);
  };

  const handleAddItem = async () => {
    const data = await fetchHomeItems();
    setItems(data);
  };

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemId]);
    } else {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    }
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedItems(items.map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleActionChange = (event) => {
    const selectedAction = event.target.value;
    setAction(selectedAction);

    switch (selectedAction) {
      case 'delete':
        handleDeleteClick(selectedItems);
        break;
      case 'activate':
        setIsConfirmActivateDialogOpen(true);
        break;
      case 'deactivate':
        setIsConfirmDeactivateDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const handleToggleActive = async (setActive) => {
    try {
      await Promise.all(selectedItems.map(id => toggleHomeItemActive(id, setActive)));
      const data = await fetchHomeItems();
      setItems(data);
      setSelectedItems([]);
      setAction(''); // Reset action after successful toggle
    } catch (error) {
      console.error(`Error ${setActive ? 'activating' : 'deactivating'} home items:`, error);
      setError(error);
    } finally {
      setAction(''); // Reset action even if there's an error
    }
  };

  const handleConfirmActivate = async () => {
    await handleToggleActive(true);
    setIsConfirmActivateDialogOpen(false);
    setAction(''); // Reset action after dialog closes
  };

  const handleConfirmDeactivate = async () => {
    await handleToggleActive(false);
    setIsConfirmDeactivateDialogOpen(false);
    setAction(''); // Reset action after dialog closes
  };

  const handleCancelActivate = () => {
    setIsConfirmActivateDialogOpen(false);
    setAction(''); // Reset action when cancel is pressed
    setSelectedItems([]); // Clear selected items on cancel
  };

  const handleCancelDeactivate = () => {
    setIsConfirmDeactivateDialogOpen(false);
    setAction(''); // Reset action when cancel is pressed
    setSelectedItems([]); // Clear selected items on cancel
  };


  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error.message}
        </Alert>
      )}
      <Card sx={{ mt: 4 }}>
        <CardHeader title="Manage Home Items" />
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2, mb: 3 }}>
            <Button variant="contained" color="primary" onClick={handleAddClick} startIcon={<AddIcon />}>
              Add New Item
            </Button>
            {selectedItems.length > 0 && (
              <FormControl>
                <Select
                  value={action}
                  onChange={handleActionChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Actions for selected items
                  </MenuItem>
                  <MenuItem value="delete">Delete</MenuItem>
                  <MenuItem value="activate">Activate</MenuItem>
                  <MenuItem value="deactivate">Deactivate</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={handleSelectAllChange}
                        checked={selectedItems.length === items.length && items.length > 0}
                        indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
                      />
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Subtitle</TableCell>
                    <TableCell>Content</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>View</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedItems.includes(item.id)}
                          onChange={(event) => handleCheckboxChange(event, item.id)}
                        />
                      </TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>{item.subtitle}</TableCell>
                      <TableCell>{item.content}</TableCell>
                      <TableCell>
                        {item.image && (
                          <AuthenticatedImage 
                            src={item.image}
                            alt={item.title} 
                            style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} 
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleViewClick(item)} sx={{ color: '#1976D2' }}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Typography color={item.set_active ? "primary" : "error"}>
                          {item.set_active ? "Active" : "Deactivated"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>

      <Dialog open={isViewDialogOpen} onClose={handleViewDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>View Item</DialogTitle>
        <DialogContent>
          {currentItem && (
            <Box>
              <Typography variant="h6">Title:</Typography>
              <Typography paragraph>{currentItem.title}</Typography>

              <Typography variant="h6">Subtitle:</Typography>
              <Typography paragraph>{currentItem.subtitle}</Typography>

              <Typography variant="h6">Content:</Typography>
              <Typography paragraph>{currentItem.content}</Typography>

              {currentItem.image && (
                <Box mt={2}>
                  <Typography variant="h6">Image:</Typography>
                  <AuthenticatedImage 
                    src={currentItem.image}
                    alt={currentItem.title}
                    style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }}
                  />
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleEditClick(currentItem)} color="primary">
            Edit
          </Button>
          <Button onClick={handleViewDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isConfirmDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this item? This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmActivateDialogOpen}
        onClose={handleCancelActivate}
      >
        <DialogTitle>Confirm Activate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate the selected item(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelActivate} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmActivate} color="primary">
            Activate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmDeactivateDialogOpen}
        onClose={handleCancelDeactivate}
      >
        <DialogTitle>Confirm Deactivate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate the selected item(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeactivate} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeactivate} color="secondary">
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>

      <AddHomeItem open={isAddDialogOpen} onClose={handleAddDialogClose} onAddItem={handleAddItem} />

      <EditHomeItem open={isEditDialogOpen} onClose={handleEditDialogClose} item={currentItem} />
    </>
  );
};

export default ViewHomeItems;