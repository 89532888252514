// src/services/TokenHandler.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
import { refreshToken as refreshAuthToken } from './auth';

const TokenHandler = ({ handleLogout, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const storedToken = Cookies.get('token');
      if (storedToken) {
        try {
          const decodedToken = jwtDecode(storedToken);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp < currentTime) {
            const data = await refreshAuthToken();
            Cookies.set('token', data.accessToken, {
              expires: 0.25,
              secure: true,
              sameSite: 'Strict',
            });
          }
        } catch (error) {
          console.error('Token refresh error:', error);
          handleLogout();
          navigate('/login'); // Redirect to login page
        }
      }
    };

    checkToken(); // Check token on component mount

    const interval = setInterval(checkToken, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [handleLogout, navigate]);

  return children;
};

export default TokenHandler;
