import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { fetchMenuItems, deleteMenuItem } from '../services/api';
import ViewMenuItemDetails from './ViewMenuItemDetails';
import EditMenuItem from './EditMenuItem';

const ViewMenuItems = ({ items, setItems }) => {
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleViewClick = (item) => {
    setCurrentItem(item);
    setIsViewDialogOpen(true);
  };

  const handleEditClick = (item) => {
    setCurrentItem(item);
    setIsEditDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentItem(null);
  };

  const handleEditDialogClose = (updated) => {
    if (updated) {
      const loadItems = async () => {
        const data = await fetchMenuItems();
        setItems(data);
      };
      loadItems();
    }
    setIsEditDialogOpen(false);
    setCurrentItem(null);
  };

  const handleDeleteClick = (itemId) => {
    setItemToDelete(itemId);
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteMenuItem(itemToDelete);
      const loadItems = async () => {
        const data = await fetchMenuItems();
        setItems(data);
      };
      loadItems();
    } catch (error) {
      console.error('Error deleting menu item:', error);
    } finally {
      setIsConfirmDialogOpen(false);
      setItemToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.price}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleViewClick(item)} sx={{ color: '#1976D2' }}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteClick(item.id)} sx={{ color: 'red' }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {currentItem && (
        <ViewMenuItemDetails
          open={isViewDialogOpen}
          onClose={handleViewDialogClose}
          item={currentItem}
          onEditClick={() => handleEditClick(currentItem)}
        />
      )}

      {currentItem && (
        <EditMenuItem
          open={isEditDialogOpen}
          onClose={handleEditDialogClose}
          item={currentItem}
        />
      )}

      <Dialog open={isConfirmDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this menu item? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewMenuItems;
