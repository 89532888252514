import React, { useState } from 'react';
import { Tabs, Tab, Box, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import RewardEarningsTable from './RewardEarningsTable';
import RewardTransactionsTable from './RewardTransactionsTable';

const ViewCustomerLoyaltyTrans = () => {
  const { customerMobileNumber } = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Paper sx={{ mt: 4, p: 2 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Customer Loyalty Transactions
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Customer Mobile Number: {customerMobileNumber}
      </Typography>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Reward Earnings" />
        <Tab label="Reward Transactions" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {currentTab === 0 && <RewardEarningsTable customerMobileNumber={customerMobileNumber} />}
        {currentTab === 1 && <RewardTransactionsTable customerMobileNumber={customerMobileNumber} />}
      </Box>
    </Paper>
  );
};

export default ViewCustomerLoyaltyTrans;
