import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const ViewMenuItemDetails = ({ open, onClose, item, onEditClick }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{item.name}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Category: {item.category}</Typography>
        <Typography variant="subtitle1">Price: ${item.price}</Typography>
        <Typography variant="body1">{item.description}</Typography>
        <img src={item.image} alt={item.name} style={{ width: '100%', marginTop: 20 }} />
        <Typography variant="subtitle1">Foodics ID: {item.foodics_id}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Close</Button>
        <Button onClick={onEditClick} color="primary">Edit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewMenuItemDetails;
