import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography,
  TablePagination,
  CircularProgress
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fetchFoodicsRewardTrans } from '../../services/api';
import ViewRewardDetails from './ViewRewardDetails';

const ViewRewardTransactions = () => {
  const [rewardTrans, setRewardTrans] = useState([]);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [currentReward, setCurrentReward] = useState(null);
  const [selectedRewards, setSelectedRewards] = useState([]);
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const loadRewardTrans = async (page, rowsPerPage) => {
    try {
      const data = await fetchFoodicsRewardTrans(page + 1, rowsPerPage);
      const sortedData = data.rewardtrans.sort((a, b) => new Date(b.date) - new Date(a.date));
      setRewardTrans(sortedData);
      setTotalRecords(data.totalRecords);
    } catch (error) {
      console.error('Error fetching reward transactions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loadRewardTrans(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleViewClick = (reward) => {
    setCurrentReward(reward);
    setIsViewDialogOpen(true);
  };

  const handleViewDialogClose = () => {
    setIsViewDialogOpen(false);
    setCurrentReward(null);
  };

  const handleCheckboxChange = (event, rewardId) => {
    if (event.target.checked) {
      setSelectedRewards([...selectedRewards, rewardId]);
    } else {
      setSelectedRewards(selectedRewards.filter(id => id !== rewardId));
    }
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedRewards(rewardTrans.map(reward => reward.id));
    } else {
      setSelectedRewards([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {selectedRewards.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <FormControl>
            <Select
              value={action}
              onChange={(event) => setAction(event.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Actions for selected items
              </MenuItem>
              {/* Add any bulk actions here */}
            </Select>
          </FormControl>
        </Box>
      )}
      <Paper sx={{ mt: 4, p: 2 }}>
        <Typography variant="h4" gutterBottom>
          Foodics Rewards Transactions
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={handleSelectAllChange}
                        checked={selectedRewards.length === rewardTrans.length && rewardTrans.length > 0}
                        indeterminate={selectedRewards.length > 0 && selectedRewards.length < rewardTrans.length}
                      />
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Reward Code</TableCell>
                    <TableCell>Customer Mobile Number</TableCell>
                    <TableCell>Branch ID</TableCell>
                    <TableCell>Business Reference</TableCell>
                    <TableCell>Discount Amount</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rewardTrans.map((reward) => (
                    <TableRow key={reward.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRewards.includes(reward.id)}
                          onChange={(event) => handleCheckboxChange(event, reward.id)}
                        />
                      </TableCell>
                      <TableCell>{reward.id}</TableCell>
                      <TableCell>{reward.reward_code}</TableCell>
                      <TableCell>{reward.customer_mobile_number}</TableCell>
                      <TableCell>{reward.branch_id}</TableCell>
                      <TableCell>{reward.business_reference}</TableCell>
                      <TableCell>{reward.discount_amount}</TableCell>
                      <TableCell>{new Date(reward.date).toLocaleString()}</TableCell>
                      <TableCell>{reward.user_id}</TableCell>
                      <TableCell>{reward.order_id}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleViewClick(reward)} sx={{ color: '#1976D2' }}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRecords}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </>
        )}
      </Paper>
      {currentReward && (
        <ViewRewardDetails
          open={isViewDialogOpen}
          onClose={handleViewDialogClose}
          reward={currentReward}
        />
      )}
    </>
  );
};

export default ViewRewardTransactions;
